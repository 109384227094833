span.divider {
  margin: 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  display: block;
}
.chemical-predective-ordering .pool-analysis {
  margin: 0px 0px;
}
.chemical-predective-ordering .right-pool-analysis {
  width: 40% !important;
}
.chemical-predective-ordering .left-pool-analysis {
  width: calc(60% - 10px) !important;
}
.pool-set-up.chemical-predective-ordering .right-pool-analysis h6 span {
  font-size: 11px;
  font-style: italic;
}
