.service-mbl {
  display: none;
}
.web-servicesproducts,
.web-servicesproducts .service-mbl {
  display: block !important;
}
.mob-servicesproducts {
  display: none;
}
.web-servicesproducts .service-container .service-title {
  display: flex;
  justify-content: space-between;
  background: #8492a8;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 60px;
}
.web-servicesproducts .service-container .service-title .btn {
  font-size: 20px;
}
.web-servicesproducts .service-container .service-title .services-header {
  display: flex;
  max-width: 70px;
  justify-content: space-between;
  width: 100%;
}
.web-servicesproducts .service-container .service-title .services-header img {
  height: 25px;
  cursor: pointer;
}
.web-servicesproducts .service-container .service-title .services-header i {
  color: white;
}
.web-servicesproducts .service-container .service-title button {
  color: white;
  border: none;
  text-decoration: none;
  padding: 0;
  text-align: left;
}
.web-servicesproducts .service-mbl .collapse {
  border-radius: 0px;
  border-top: none;
  background-color: #fff;
}
.web-servicesproducts
  .service-mbl
  .outer-accordion.accordion
  .heading-accordion {
  padding: 0px;
  background: transparent;
}
.web-servicesproducts .mob-accordion .card-header {
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.1);
}
.web-servicesproducts .service-edit-icon.add-sort {
  max-width: 150px;
  width: 100%;
}
.web-servicesproducts .sorting img {
  margin-top: 0px !important;
  width: 22px;
  margin: 0;
}
.web-servicesproducts .action-web-btn {
  display: flex;
  align-items: center;
  max-width: 100px;
  width: 100%;
  justify-content: space-between;
}

.web-servicesproducts .action-web-btn img {
  height: 18px;
}
.web-servicesproducts .mob-accordion .card-header .btn.btn-link {
  width: 90%;
}
.web-servicesproducts .inner-accordion .accordion {
  margin-top: 20px;
}
.web-servicesproducts .inner-accordion .accordion .card .accordion {
  margin: 0;
}
.web-servicesproducts .header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1.5px solid #dedede;
  padding: 20px 0px 10px;
}

.web-servicesproducts .header-section h6 {
  font-weight: 600;
  margin: 0;
  padding: 0px 15px;
  width: 10%;
}
.web-servicesproducts .header-section h6 span {
  font-size: 12px;
}

.web-servicesproducts .header-section h6:first-child,
.web-servicesproducts .header-section h6:nth-child(2),
.web-servicesproducts .header-section .services-body-list:first-child,
.web-servicesproducts .header-section .services-body-list:nth-child(2) {
  width: 20%;
}
.web-servicesproducts .header-section h6:nth-child(2),
.web-servicesproducts .header-section .services-body-list:nth-child(2) {
  width: 30%;
}
.web-servicesproducts .header-section .services-body-list {
  width: 10%;
}
.web-servicesproducts .header-section p {
  font-weight: 400;
  margin: 0;
  padding: 0px 15px;
}
.web-servicesproducts .service-mbl .outer-accordion.accordion .card-body {
  padding-left: 0px;
  padding-right: 0px !important;
}
.web-servicesproducts .heading-accordion .btn {
  padding: 10px 5px;
}
.web-servicesproducts .action-web-btn i {
  font-size: 24px;
  margin-left: 5px;
  cursor: pointer;
  color: #8d8b8b;
}
.web-servicesproducts .service-mbl .mob-accordion {
  padding: 0px 0px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #dedede;
}
.web-servicesproducts .mob-accordion.accordion .card-body {
  padding: 0 10px 0px;
}
.web-view-chlorinator .accordion {
  border: 1px solid #efefef !important;
  border-radius: 10px;
}
.web-servicesproducts
  .card-body
  .web-servicesproducts
  .body-section-list:last-child {
  border-bottom: none !important;
}
.web-servicesproducts .view-btn .btn {
  font-size: 16px !important;
  height: 45px !important;
  max-width: 150px;
}
.web-servicesproducts .action-icon i {
  font-size: 24px;
  margin-right: 8px;
  cursor: pointer;
}

.web-servicesproducts .action-icon i.las.la-edit {
  color: #0077c0;
}

.web-servicesproducts .action-icon i.las.la-trash-alt {
  color: #ff0000b0;
}

.web-servicesproducts .action-icon {
  display: flex;
}
.web-servicesproducts .service-mbl .service-title h4 {
  width: 85%;
}
.web-servicesproducts .service-mbl .service-title h4 button {
  width: 100%;
}
.web-servicesproducts
  .service-mbl
  .mob-accordion
  .body-section-list.header-section:last-child {
  border-bottom: none !important;
}
.web-servicesproducts .header-section p span {
  font-size: 14px;
  color: #9b9b9b;
}
.web-servicesproducts .header-section .item-type {
  margin-top: 10px;
}
@media screen and (max-width: 1536px) {
  .web-servicesproducts .service-container .service-title {
    height: 50px;
  }
  .web-servicesproducts .service-container .service-title .btn {
    font-size: 18px;
  }
  .product-service-container .mob-table .heading-accordion h5 {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .web-servicesproducts {
    display: none !important;
  }
  .mob-servicesproducts {
    display: block;
  }
  .mob-servicesproducts .dropdown-menu.show {
    min-width: 11rem;
    box-shadow: 0px 0px 3px 0px #e8dfdf;
    border: none;
  }
  .mob-servicesproducts .dropdown-menu .action {
    font-size: 14px;
    line-height: 16px;
    color: #b4b5b8;
    padding: 8px 12px;
  }
  .mob-servicesproducts .dropdown-menu .action img {
    width: 14px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 991px) {
  .web-servicesproducts
    .service-container
    .service-title
    .services-header.mineral-pool-head-img {
    max-width: fit-content;
  }
}
