.email-sending-temp.search-invoice.pool-set-up.sms-invocie-template.new-sms-template {
  background: #f6f7fb;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
.sms-heading {
  text-align: center;
}
.smssending-days-section {
  padding: 0px 10px;
  margin-top: 45px;
}
.paynowdue {
  border-radius: 9px;
  line-height: 20px;
  height: 40px;
  min-width: 100px;
}
.new-sms-template .margin-values {
  margin-bottom: 13px !important;
}
