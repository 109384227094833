.login-container {
  background: #e0e8f5;
  height: 100vh;
  padding-top: 26px;
}

.login {
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  padding: 0px 10px;
}
.login-box {
  background: white;
  padding: 12px 25px;
  padding-bottom: 35px;
  border-radius: 10px;
  color: #fff;
}
.logo-login {
  width: 150px;
  text-align: center;
  margin: 0 auto;
  border-radius: 0px;
}
.logo-login img {
  width: 100%;
  /* height: 100%; */
  height: 85.77px;
  object-fit: contain;
}
h3.login-title {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Exo 2", sans-serif;
}

.login-form label.form-label {
  color: #171717;
  font-size: 18px;
  font-weight: 600;
}
.login-form .form-control {
  color: #333;
  border: transparent;
  font-size: 16px;
  padding: 8px 15px;
  box-shadow: none;
}
.login-form .form-group {
  margin-bottom: 35px;
}
.checkbox.form-group {
  margin-bottom: 20px;
}
.login-popup-btn .btn {
  border-radius: 10px;
  padding: 6px 10px;
}
.login-popup-btn .btn-primary,
.login-popup-btn .btn-primary:hover,
.login-popup-btn .btn-primary:focus {
  color: #fff;
  background-color: #1aa01d;
  border-color: #1aa01d;
  box-shadow: none;
}
.login-popup-btn .btn-primary:not(:disabled):not(.disabled).active,
.login-popup-btn .btn-primary:not(:disabled):not(.disabled):active,
.login-popup-btn .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1aa01d !important;
  border-color: #1aa01d !important;
  box-shadow: none !important;
}
.login-text,
.login-links {
  text-align: center;
  font-weight: 400;
  color: #8d8d8d;
  font-size: 18px;
}
.login-text > a,
.login-links > p > a {
  color: #0077c0 !important;
  /* padding: 0 5px; */
  transition: 0.3s all ease-in;
}
/* .login-text > a,
.login-links > p > a:hover {
  text-decoration: underline;
} */

.login-form .form-group .form-control {
  height: 36px;
  border: 1px solid #e0dede;
  font-weight: 400;
  padding-right: 50px;
}
.login-text {
  text-align: center;
  margin-top: 40px;
}
.login-popup-btn {
  text-align: center;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form input::placeholder {
  color: #8d8d8d !important;
  font-size: 15px;
}
.login-form {
  margin-top: 14px;
}
.login-form .form-group .form-control:focus {
  color: #8d8d8d !important;
}
.login-form label.form-label {
  margin-bottom: 2px;
}
.login-links {
  margin-top: 15px;
}
.login-popup-btn button {
  width: 48%;
}
.login-popup-btn .btn-edit {
  background-color: transparent;
  border-color: #747070 !important;
  color: #747070 !important;
}
.reset-info {
  color: dimgrey;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  margin: 15px 0px;
}
.login-container.reset-password .login-popup-btn {
  display: flex;
  justify-content: space-between;
}
.login-form .form-group {
  position: relative;
}

.login-form .form-group i {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #707070 !important;
  background: transparent !important;
  height: 36px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0dede;
  border-left: none !important;
  border-radius: 5px;
}

.new_forget_btn a {
  width: 48%;
}

.new_forget_btn a .btn {
  min-width: unset;
  width: 100%;
}
