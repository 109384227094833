@media screen and (min-width: 991px) {
  .pool-setup .pool-analysis {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }
  .right-pool-analysis h6 {
    font-size: 13px;
    min-width: 50px !important;
    width: 100%;
  }
  .pool-set-up .view-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 0;
  }
  /* .pool-set-up .pool-chemistry-box .view-btn {
    justify-content: flex-end;
  } */
  .pool-set-up .view-btn button {
    margin-left: 20px;
  }

  .pool-set-up .left-pool-analysis h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
    margin: 0;
  }
  .pool-set-up input:focus {
    outline: none;
    box-shadow: none;
    border: none !important;
  }
  .pool-chemistry-box.chemistry-target .pool-analysis {
    height: 64px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .pool-chemistry-box.chemistry-target .pool-analysis:nth-child(even) {
    background: rgba(51, 51, 51, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .pool-set-up .right-pool-analysis h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
  }
  .pool-set-up .service-edit-icon button img {
    width: 10px !important;
    margin: 0px !important;
  }
  .pool-set-up .target-heading {
    display: none;
  }
  .pool-set-up .pool-chemistry-box.chemistry-target .left-pool-analysis h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
  }
  .pool-set-up .view-btn .client-option.service-edit-icon {
    width: auto;
  }
  .pool-set-up .view-btn .dropdown button {
    padding: 0px 12px;
  }
  .pool-bg {
    background: #ffffff;
    border-radius: 8px;
    padding: 0px 0px;
  }
  .admin-container {
    background: #f6f7fb;
  }
  .pool-set-up .main-titles-mob {
    /* margin-bottom: 0px;
    padding: 0px 30px;
    max-width: 60%;
    margin-left: auto; */
    display: none;
  }
  /* .pool-set-up {
    padding-top: 20px;
  } */
  /* .pool-chemistry-box.chemistry-target .pool-analysis:nth-child(1) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  } */
  .pool-chemistry-box {
    padding: 0px 0px 20px;
    margin-top: 0;
  }
  .client-option.service-edit-icon .dropdown-menu {
    min-width: 14rem !important;
  }
  .client-option.service-edit-icon .dropdown-menu .action {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #b4b5b8;
  }
  .action img {
    width: 14px;
    margin-right: 5px;
  }
  .pool-set-up .input-with-icons.poolsetup-input.form-group {
    min-width: 50px;
    width: fit-content;
    margin: 0px auto;
  }
  .pool-set-up .view-btn .btn {
    width: 100% !important;
    max-width: 185px !important;
  }
  .dropdown-option-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .dropdown-option-section .option-list {
    background: white;
    padding: 10px 12px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 0px 20px;
    display: flex;
    align-items: center;
    width: 208px;
    min-height: 72px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #b4b5b8;
    cursor: pointer;
    border: 1px solid white;
  }
  .dropdown-option-section .active-menu {
    border: 1px solid #0077c0;
  }
  .dropdown-option-section .option-list img {
    width: 16px;
    margin-right: 10px;
  }
  .webview-pool-section {
    display: block;
  }
  .webview-pool-section .top-heading h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
  .pool-set-up .service-mbl {
    display: block;
  }
  .chemical-setup .right-pool-analysis.target-level-input form {
    width: 33.33%;
  }
  .pool-set-up
    .pool-chemistry-box.chemistry-target
    .left-pool-analysis
    h6:first-child {
    text-transform: uppercase;
  }

  /************ chemical to display *********/
  .pool-setup.pool-chemistry-box.chemistry-target .chemical-display-section {
    padding: 0;
    margin: 0;
  }
  .pool-setup.pool-chemistry-box.chemistry-target
    .chemical-display-section
    .chemical-list {
    margin-bottom: 0px;
    height: 64px;
    padding: 10px 134px 10px 20px;
  }
  .pool-setup.pool-chemistry-box.chemistry-target
    .chemical-display-section
    .chemical-list:nth-child(even) {
    background: rgba(51, 51, 51, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  /************** drain pool setup **********/
  .drainage-cost-section .pool-chemistry-box {
    padding: 0px 25px;
  }

  .drainage-cost-section .row {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    align-items: center;
    height: 74px;
  }
  .drainage-cost-section form {
    width: 100%;
    margin-left: auto;
    max-width: 380px;
  }
  .drainage-cost-section .col-4 h5 {
    width: 100%;
    margin-left: auto;
    max-width: 380px;
    text-align: center;
  }
  .drainage-cost-section .target-heading {
    display: block;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    height: 74px;
    display: flex;
    align-items: center;
  }

  /************ chlorinator models **************/
  .webview-pool-section .service-mbl.pool-accordian .heading-accordion h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
  }

  .pool-set-up .mob-accordion.outer-accordion.accordion {
    padding: 0 !important;
  }
  .service-mbl.pool-accordian.web-view-chlorinator {
    display: block;
  }
  .service-mbl.pool-accordian.mobile-view-chlorinator {
    display: none;
  }
  .service-mbl.pool-accordian.web-view-chlorinator
    .mob-accordion
    .card-header
    .btn.btn-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .service-mbl.pool-accordian.web-view-chlorinator .mob-accordion .card-header {
    padding: 5px 0px;
    background: transparent;
  }
  .service-mbl.pool-accordian.web-view-chlorinator .mob-accordion {
    margin-bottom: 30px;
  }
  .web-view-chlorinator .header-section h6,
  .web-view-chlorinator .header-section .services-body-list {
    width: 13% !important;
    text-align: center;
  }
  .web-view-chlorinator .header-section h6:nth-child(1),
  .web-view-chlorinator .header-section .services-body-list:nth-child(1) {
    width: 22% !important;
    text-align: left;
  }

  .web-view-chlorinator .accordion > .card > .card-header {
    background: transparent !important;
  }
  .web-view-chlorinator .service-container .service-title {
    background-color: transparent !important;
  }
  .web-view-chlorinator .service-container .service-title .btn {
    font-size: 18px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* text-transform: uppercase; */
    color: #3c424b;
  }
  .web-view-chlorinator .sorting img {
    width: fit-content !important;
  }
  .pool-bg .web-servicesproducts .action-icon {
    justify-content: center;
  }
  .pool-bg .web-servicesproducts .header-section h6 {
    padding: 0px 8px;
  }
  /* .service-mbl.pool-accordian.web-view-chlorinator .accordion {
    border: none;
  } */
  .web-view-chlorinator .sorting.up-down.active-sort img {
    height: 30px;
  }
  .balance-water-box .custom-select {
    background-color: transparent;
    border: 1px solid #e0e0e0;
  }
  .balance-water-box .custom-select {
    height: 40px !important;
  }
  .pool-setup.balance-water.drainage-cost-section {
    padding-bottom: 20px;
  }
  .pool-chemistry-box.balance-water-box .free-chlorine-setup.inner-chlorine,
  .pool-chemistry-box.balance-water-box .free-chlorine-setup {
    margin: 0;
  }
  .pool-chemistry-box.balance-water-box .free-chlorine-setup.inner-chlorine h5 {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    height: 74px;
    display: flex;
    align-items: center;
  }
  .pool-setup.balance-water.drainage-cost-section .target-heading {
    border: none;
    height: 64px;
  }

  .pool-setup.balance-water.drainage-cost-section .target-heading h6 {
    /* text-transform: uppercase; */
    font-size: 18px;
  }

  /********* green pool calc *********/
  .green-pool-cal {
    padding: 20px 20px;
  }
  .green-pool-cal .pool-table-content .right-pool-table {
    align-items: center;
    justify-content: flex-end;
  }
  .green-pool-cal .pool-table-content .target-level-input .form-control {
    padding: 8px 8px;
    text-align: right;
  }
  .pool-chemistry-box.green-pool-calculation .left-pool-table.green-one {
    width: 50%;
  }
  .green-pool-table.pool-cost-table .right-pool-table {
    width: 50%;
  }
  .search-pool-size {
    padding: 0px 20px;
  }
  .green-pool-cal .pricing-setup h6 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #20b1e4;
    text-align: center;
  }
  .green-pool-calculation.green-pool-cal .main-title h6 {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 24px;
    /* text-transform: uppercase; */
    color: #3c424b;
    margin-bottom: 30px;
  }
  .green-pool-cal .green-pool-table {
    margin-top: 25px;
  }
  .green-pool-cal .green-pool-table h5 {
    margin-bottom: 25px;
  }
  .green-pool-cal .green-pool-table .main-table {
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 10px;
    padding: 0px 0px;
  }
  .green-pool-cal .pool-table-content,
  .pool-chemistry-box.green-pool-calculation .pool-table {
    padding: 0px 10px;
  }
  .left-pool-table {
    border-radius: 6px;
    padding: 6px 5px;
  }
  .green-pool-cal .pool-table p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #0077c0;
  }
  .green-pool-cal .target-level-input .form-control {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #0077c0;
  }
  .green-pool-cal hr,
  .pool-cost-table hr {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    opacity: 1;
  }
  .pool-chemistry-box.green-pool-calculation {
    padding: 0px 20px;
  }
  .pool-cost-table .pool-color {
    border: 1px solid rgba(51, 51, 51, 0.1);
    padding: 10px 10px;
    margin: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .pool-cost-table .main-table {
    border-left: 1px solid rgba(51, 51, 51, 0.1);
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .pool-cost-table .dosage-part .pool-table-content {
    padding: 0px 10px;
  }
  .pool-cost-table .pool-table-content {
    height: 60px;
  }
  .pool-cost-table .left-pool-table.second-dosage p {
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 21px;
    color: #3c424b;
  }
  .pool-chemistry-box.green-pool-calculation.green-pool-cal {
    padding: 20px 20px;
  }
  .green-pool-table .main-table .pool-table {
    height: 62px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 20px;
  }
  .green-pool-table .main-table .pool-table p {
    margin: 0 !important;
    display: flex;
    align-items: center;
  }
  .green-pool-table.pool-cost-table .right-pool-table p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  /********** second visit cost ***********/
  .pool-setup-second-visit .row {
    border-bottom: none;
    height: 100%;
  }
  .pool-setup-second-visit .form-group {
    margin-bottom: 40px;
  }
  .pool-setup-second-visit .form-group label {
    color: #333;
    line-height: initial;
    margin-bottom: 20px;
    padding: 0 !important;
    height: auto;
  }
  .pool-chemistry-box.appointment-service-modal.pool-setup-second-visit {
    padding: 20px 25px;
  }
  .pool-setup.mineral-pool.drainage-cost-section .bottom-text {
    padding-bottom: 20px;
  }
  /* .pool-setup.mineral-pool.drainage-cost-section .target-heading h6{
  text-transform: uppercase;
} */

  /* Acid Wash Page */
  .acid-wash .main-title {
    padding: 20px 0;
  }
  .acid-wash .main-title h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* text-transform: uppercase; */
    color: #3c424b;
    margin: 0;
  }
  .acid-wash-inner {
    padding: 10px 10px 0;
  }
  .acid-wash .pricing-setup h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: initial;
    /* text-transform: uppercase; */
    /* text-align:center; */
    margin-bottom: 5px;
  }
  .acid-wash .pricing-setup p {
    font-weight: 400;
    font-size: 16px;
    line-height: initial;
    /* text-transform: uppercase; */
  }
  .acid-wash .green-pool-table {
    margin-top: 25px;
  }
  .acid-wash .green-pool-table h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .acid-wash .green-pool-table .main-table .pool-table p,
  .acid-wash .left-pool-table.second-dosage p:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .acid-wash .pool-table-content {
    padding: 10px;
  }
  .acid-wash .left-pool-table {
    padding: 0;
  }
  .acid-wash .pool-table-content .right-pool-table {
    justify-content: flex-end;
    padding: 0;
  }
  .acid-wash .right-pool-table p {
    display: flex;
    justify-content: flex-end;
  }
  .acid-wash .right-pool-table .form-group {
    display: flex;
    align-items: center;
  }
  .acid-wash .main-table {
    margin-top: 25px;
  }
  .table-border {
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 10px;
  }
  .acid-wash hr {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
  .acid-wash .right-pool-table .form-group,
  .acid-wash .right-pool-table span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .acid-wash .right-pool-table form input {
    border: 2px solid transparent;
    text-align: right !important;
    padding: 0px 6px 0 4px !important;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 19px;
  }
  .acid-wash .right-pool-table .form-group span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    margin-top: 0;
    /* left: 36px; */
  }
  .acid-wash .note-info {
    font-size: 14px;
    line-height: 19px;
    padding: 20px 0 0;
  }
  .acid-wash-page.search-pool-size {
    padding: 20px;
    margin: 0;
  }
  .acid-wash-page.search-pool-size .form-group span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .acid-wash-page.search-pool-size input {
    height: 39px !important;
  }
  .acid-wash-page .green-pool-table h5 {
    margin: 0;
    padding: 0 20px 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .acid-wash-page .pool-color h6 {
    padding-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .acid-wash-page .right-pool-table h6 {
    align-self: flex-start;
  }
  .acid-wash-page .left-pool-table {
    padding: 0;
  }
  .acid-wash-page .pool-table-content {
    height: fit-content;
    padding: 5px 10px !important;
  }
  .acid-wash-page .dosage-part .pool-table-content:first-child {
    padding-top: 10px !important;
  }
  .pool-cost-table hr:last-child {
    border: none;
    margin: 5px 0 0;
  }

  /* Media Replacement */
  .media-replacement .pricing-setup {
    margin-top: 10px;
  }
  .media-replacement .filter-table .dosage-part {
    padding: 20px 10px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 0;
  }
  .media-replacement .pool-table-content {
    padding: 0;
  }
  .media-replacement .main-table .pool-table {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 0;
  }
  .media-replacement .filter-table .pool-table-content input {
    text-align: right;
    padding-right: 4px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .media-replacement .pool-table-content p span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .media-replacement
    .dosage-part.filter-bottom-table
    .pool-table-content
    p:last-child
    span {
    text-align: right !important;
    width: fit-content;
    min-width: fit-content;
    padding-left: 5px;
  }
  .acid-wash
    .filter-glass-table
    .green-pool-table.filter-table
    .glass-bottom-table
    p:first-child {
    text-align: left !important;
  }
  .media-replacement-page.media-replacement-page
    .green-pool-table.filter-table
    .glass-bottom-table
    p:first-child {
    width: 92% !important;
  }
  .filter-glass-table.media-replacement-page
    .green-pool-table.filter-table
    .pool-table-content
    p {
    width: 8% !important;
  }
  .media-replacement .pool-table-content.glass-bottom-table {
    justify-content: flex-end;
    padding-top: 10px;
  }
  .media-replacement .pool-table-content.glass-bottom-table p:first-child {
    padding-right: 15px;
  }
  .media-replacement .pool-table-content.glass-bottom-table p:last-child {
    width: 12% !important;
    text-align: left;
    justify-content: flex-start;
  }

  /* Mineral Popup */
  .miniral-popup .modal-content {
    padding-bottom: 20px !important;
  }
  .service-mbl.pool-accordian .accordion {
    border: none !important;
  }
  .web-servicesproducts .inner-accordion .accordion {
    border: 1px solid #efefef !important;
    border-radius: 10px;
  }
  .web-servicesproducts .inner-accordion .header-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #efefef;
    padding: 20px 0px 10px;
  }
  .media-replacement .green-pool-table .main-table .pool-table p,
  .media-replacement .left-pool-table.second-dosage p:last-child {
    justify-content: flex-end;
  }
  .balance-water-box .custom-select {
    height: 40px !important;
    font-weight: 400;
    font-size: 14px !important;
  }
}

@media (min-width: 767px) {
  /* Mineral Popup */
  .miniral-popup .modal-body input,
  .miniral-popup .modal-body .form-control {
    padding: 6px 20px !important;
    line-height: 1;
    font-weight: 400;
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .pool-set-up .pool-chemistry-box.chemistry-target .left-pool-analysis h6 {
    display: none;
  }
  .pool-set-up .right-pool-analysis .form-group .form-control {
    width: 100% !important;
  }
  .webview-pool-section {
    display: none;
  }
  .service-mbl.pool-accordian.web-view-chlorinator {
    display: none;
  }
  .service-mbl.pool-accordian.mobile-view-chlorinator {
    display: block;
  }
  .view-btn .desktop-view {
    text-align: center !important;
  }
}
