.payment-popup input,
.payment-popup .StripeElement {
  padding: 10px 14px;
  border-radius: 5px;
  background: white;
  height: 35px;
  border: 1px solid #ced4da;
  padding: 10px 10px !important;
}
.payment-popup .StripeElement .__PrivateStripeElement {
  margin-top: 5px !important;
}
.payment-popup .service-submit {
  display: flex;
  justify-content: flex-end !important;
}
.billSummary .card_input {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  border-radius: 5px;
  height: 32.8px;
  align-content: center;
  text-align: center;
  border: 1px solid #e0e0e0;
  background: white;
  padding: 0 10px;
}

.billSummary .payment-form1 label.form-label {
  height: 24px;
  margin-top: 10px;
  color: #767575;
}
.billSummary .payment-form1 input,
.billSummary .payment-form1 input::placeholder {
  color: #767575;
  font-weight: 500;
  font-size: 14px;
}

.pt-12 {
  padding-top: 12px;
}
