.received-payments .appointment-view {
  padding: 15px;
}
.received-payments .appointment-container .add-appointment {
  margin-bottom: 20px;
}
.received-payments .appointment-view.appointment-service-modal h2 {
  text-align: center;
  font-weight: 500;
}
.received-payments .appointment-view label {
  height: auto;
  line-height: 22px;
  font-size: 14px;
  color: #333;
}
.received-payments .added-chemicals h6 {
  font-weight: 600;
}
.received-payments .added-chemicals {
  padding: 2px 10px;
}
.received-payments .client-notes .basic input:checked:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  padding: 11px;
  background-size: cover;
  background-color: #5bd125;
  border-radius: 50%;
  border: none !important;
}

/************ past future booking ************/
.booking-page .target-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-page .appointment-view {
  margin-top: 25px;
  padding: 10px 12px;
}
.booking-page .booking-date span {
  font-weight: 500;
}
.booking-date.past-date {
  color: #1aa01d;
}

.booking-page .booking-date {
  font-size: 14px;
  line-height: initial;
  font-weight: 700;
  padding-right: 10px;
  width: 22%;
}
.booking-page .completed-booking {
  color: #1aa01d;
  font-weight: 500;
  font-size: 14px;
}
.booking-page span.sep {
  display: block;
}
.booking-page .add-appointment {
  padding: 10px 0px;
}
.booking-page .booking-date-section {
  display: flex;
  width: 90%;
}
.booking-page span.sep:last-child {
  display: none !important;
}
.booking-page .client-profile p {
  line-height: 14px;
  margin-bottom: 0px;
}

/**************Water Hisory *******************/
.water-history .water-history-table {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  margin-bottom: 10px;
}
.water-history .water-history-table .water-table-date {
  padding-right: 10px;
  width: 12%;
}
.water-history .invoices-head h6 {
  white-space: nowrap;
  width: 20% !important;
}
.water-history .table-section {
  width: 90%;
}
.water-history .invoices-head h6:nth-child(3),
.water-history .invoice-body p:nth-child(3) {
  width: 35% !important;
}
.water-history .invoices-head h6:first-child,
.water-history .invoice-body p:first-child {
  width: 20% !important;
}
.water-history .invoices-head h6,
.water-history .invoice-body p {
  padding-right: 10px;
}
.water-history .invoices-head {
  justify-content: space-between;
}
.water-history .invoice-body {
  justify-content: space-between;
}
.water-history .table-section {
  border-bottom: none;
}
.water-history .water-table-date span {
  font-weight: 500;
  font-size: 11px;
}

.water-history .water-table-date {
  font-weight: 700;
  line-height: initial;
  color: #0077c0;
  font-size: 13px;
}
.water-history .invoice-body p:first-child {
  color: #a7a7a7;
  font-weight: 600;
}
.water-history .invoice-body p {
  font-size: 11px;
  line-height: 15px;
}
.water-history .invoices-head h6 {
  font-size: 10px;
}
.water-history .invoices-head h6:first-child {
  color: #333;
}
.history-total h6 {
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  padding-top: 5px;
}
.water-history .table-section {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.water-history .user-pagination {
  padding-top: 20px !important;
}
.water-history .water-history-table:last-child {
  border-bottom: none !important;
}

/*******************invoice-payment-page ********************/
.invoice-payment-page .target-heading.client-profile {
  padding: 0px;
}
.invoice-payment-page .search-box.form-group {
  display: flex;
}
.invoice-payment-page .search-invoces-box.payment-method {
  margin-bottom: 15px;
  background-color: #f7f9ff;
}
.invoice-payment-page .total-applied {
  background-color: #0070c0 !important;
  color: white !important;
  margin-top: 15px;
}
.invoice-payment-page .total-applied label {
  color: #fff !important;
}
.invoice-payment-page .payment-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  color: #0070c0;
}

.invoice-payment-page .invoice-detail-section {
  width: 90%;
}

.invoice-payment-page .payment-details-box {
  display: flex;
  align-items: center;
}

.invoice-payment-page .payment-details {
  display: flex;
  justify-content: space-between;
}

.invoice-payment-page .payment-details h6 {
  width: 50%;
  font-size: 12px;
  font-weight: 600;
  color: #0070c0;
}

.invoice-payment-page .invoices-table-right {
  width: 10%;
}
.invoice-payment-page .minor label.form-label {
  color: #28b8f2 !important;
}
.payment-applied.form-group label {
  color: #0070c0 !important;
}
.invoice-payment-page .view-btn.search-invoice-btn {
  display: flex;
  justify-content: flex-end;
}
.invoice-payment-page .target-heading i {
  color: #0077c0;
}

.invoice-payment-page .target-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.multiple-invoice p {
  font-style: italic;
  margin-bottom: 0;
  font-size: 12px;
  line-height: initial;
  color: #28b8f2;
}
.multiple-invoice {
  margin-bottom: 12px;
}
.invoice-payment-page .payment-details h6:first-child {
  width: 60%;
}

/************* direction popup *****************/
.direction-popup iframe {
  width: 100%;
  height: 430px;
}

.direction-page .modal-content {
  padding: 0px !important;
}

.staff-modal.view-appointment-popup.direction-page .modal-body {
  padding: 0px !important;
}
.direction-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.direction-details {
  padding: 10px;
}

.direction-heading h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}

.direction-btn button {
  width: 100%;
  background-color: #007bff;
  color: white;
  line-height: initial;
  font-weight: 500;
}

.direction-btn button span {
  font-weight: 300;
}

.direction-btn {
  margin-top: 20px;
}

.address p {
  margin-bottom: 0px;
  color: #797979;
}

.address {
  margin-top: 30px;
}

.address h6 {
  line-height: initial;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.share-direction {
  display: flex;
  justify-content: space-between;
}

.share-direction button {
  width: 48%;
  background-color: #e9e8e6;
  padding: 10px;
  border: 1px solid #e9e8e6;
  display: inline-grid;
  justify-content: center;
  color: #007bff;
  font-weight: 500;
  font-size: 12px;
}
.share-direction {
  margin-top: 25px;
  margin-bottom: 20px;
}
.share-direction button img {
  height: 18px;
}
.share-direction button p {
  margin-bottom: 0px;
}
.direction-close {
  height: 28px;
  width: 28px;
  background-color: #e9e8e6;
  border-radius: 50%;
  text-align: center;
}

.direction-close i {
  font-size: 19px;
  opacity: 0.7;
  line-height: 28px;
}
.invoice-payment-page .select-box {
  position: relative;
  width: calc(100% + 20%);
}
.checklist-popup .added-chemicals {
  border: none;
  margin-bottom: 0px;
  padding: 5px 0px;
}
.checklist-popup .added-chemicals h6 {
  width: 80%;
  font-size: 12px;
  color: #7d7575;
  display: flex;
}
.checklist-popup .added-chemicals h6 span {
  padding-right: 5px;
}
.checklist-popup .photo-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checklist-popup .photo-icon h6 {
  margin-bottom: 0px;
  font-size: 14px;
}

.checklist-popup .photo-icon i {
  color: #048809;
  font-size: 40px;
}

.checklist-popup .take-photo {
  margin-top: 20px;
}
.checklist-popup .show-img img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.checklist-popup .show-img {
  margin: 10px 0px;
  position: relative;
}
.checklist-popup .footer-btn.view-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.checklist-popup .replace-img img {
  height: 16px;
  width: auto;
  opacity: 0.6;
}

.checklist-popup .replace-img {
  position: absolute;
  top: 6px;
  right: 6px;
  height: 25px;
  width: 25px;
  background-color: #f6f7fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  cursor: pointer;
}
.mb-3.form-group {
  position: relative;
}

.checklist-popup .mb-3.form-group img {
  height: 12px;
  position: absolute;
  right: 8px;
  top: 12px;
  opacity: 0.6;
}
.received-payments .appointment-service-modal .payment-top-heading input,
.received-payments .appointment-service-modal .payment-top-heading input:focus {
  text-align: center;
  width: 100%;
  border: none;
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 10px;
  outline: none;
  box-shadow: none;
}
.water-history .invoices-head h6:nth-child(2),
.water-history .invoice-body p:nth-child(2) {
  width: 15% !important;
  padding-right: 10px;
}
.water-history .invoices-table-left {
  width: 98%;
}
.water-history .invoices-table-right {
  width: 2%;
  padding-left: 15px;
}
.water-history .invoice-body p:last-child,
.water-history .invoices-head h6:last-child {
  text-align: right;
}

.water-history .invoices-head h6:nth-child(4),
.water-history .invoice-body p:nth-child(4) {
  width: 18% !important;
  padding-right: 10px;
}
/************mark as complete *****************/
.mark-complete .add-appointment.search-container {
  padding: 10px 0px;
}
.mark-complete .appointment-total h6:first-child {
  color: #0071c1 !important;
  font-weight: 700 !important;
}
.mark-complete .appointment-total h6,
.mark-complete .appointment-total h6 span {
  color: #0096ee;
}
.mark-complete .staff-close span img {
  height: 17px;
  transform: rotate(90deg);
  opacity: 0.5;
}
.mark-complete .staff-close span {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 20px;
}
.mark-complete .client-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.mark-complete .site-address {
  border: none !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.mark-complete .appointyment-type {
  margin-top: 0px !important;
}
.mark-complete .add-appointment.search-container {
  margin-bottom: 5px;
}
.mark-complete .group-time {
  color: #e60606 !important;
}
.mark-complete .appointment-view .search-title {
  margin-bottom: 15px;
}
.received-payments .payment-top-heading p {
  margin-bottom: 0px;
  text-align: center;
  margin-top: -14px;
  font-size: 12px;
}

.received-payments .payment-top-heading h6 {
  margin-bottom: 0px;
  text-align: center;
  color: #7a7a7a;
}
.mark-complete a.btn.btn-primary.btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mark-complete a.btn.btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
}
.future-bookings .booking-date.past-date {
  color: #0077c0;
}
.future-bookings p.completed-booking {
  color: #0077c0;
}
.booking-date-section .client-profile {
  width: 75%;
}
.payment-search .pool-chemistry-box.appointment-service-modal .target-heading {
  padding: 0px 0px !important;
}
.payment-search .form-group label {
  color: #28b8f2 !important;
  font-weight: 600 !important;
}
.mark-complete .appointment-view {
  padding: 0px 0px;
}
.mark-complete .appointment-view .search-title.service-head {
  margin-bottom: 0px;
}

.mark-complete .appointment-view .search-title {
  margin-bottom: 0px;
}
.mark-complete .appointment-view .appointment-form {
  padding: 20px 0 0;
}

.invoice-payment-page .search-invoces-box.payment-method input.form-control {
  width: 30% !important;
}

.invoice-payment-page .search-invoces-box.payment-method label.form-label {
  width: 50%;
}

.invoice-payment-page .search-invoces-box.payment-method input.form-control {
  width: 50% !important;
}
.invoice-payment-page
  .search-invoces-box.payment-method
  .search-box.form-group {
  display: inline-flex;
}
.invoice-payment-page .search-invoces-box.payment-method .select-box {
  width: 50% !important;
}
.outstandings-invoices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.outstandings-invoices .open-date.client-notes {
  width: auto;
}

.outstandings-invoices h6 {
  margin-bottom: 0px;
  color: #0070c0;
}
.search-box.search-invoice-box.form-group .search-box.form-group {
  width: 50% !important;
}
.search-box.search-invoice-box.form-group .custom-datepicker {
  width: 100% !important;
}

.booking-page .completed-booking .appcompleted {
  color: #0dbf13 !important;
}
.booking-page .completed-booking .appcancelled {
  color: red !important;
}
.booking-page .completed-booking .appbooked {
  color: #0077c0 !important;
}

.water-history .user-pagination {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}

.booking-page .booking-date span.text-d {
  display: none;
}

.water-history .water-history-table .water-table-date.text-d {
  display: none;
}

@media screen and (min-width: 991px) {
  .pool-set-up.invoices-list.water-history .invoices-table.pool-chemistry-box {
    padding: 0px 25px;
  }
  .booking-page.past-bookings .booking-date,
  .booking-page.future-bookings .booking-date {
    width: 8%;
  }
  .booking-page .booking-date span.text-d {
    display: inline;
    font-weight: 600;
  }
  .booking-page .booking-date span.text-m {
    display: none;
  }

  .water-history .water-history-table .water-table-date,
  .water-history .water-history-table .water-table-date span {
    width: 8%;
    font-size: 14px;
  }
  .water-history .table-section {
    width: 92%;
  }
  .water-history .invoice-body p:nth-child(4) {
    align-items: flex-end;
  }
  .water-history .invoice-body p {
    text-transform: capitalize !important;
  }
  .checklist-popup .show-img img {
    height: 280px;
  }
  .checklist-popup .show-img .replace-img img {
    height: 16px;
    width: auto;
    opacity: 0.6;
  }
  .booking-page .target-heading {
    align-items: flex-start;
  }
}
