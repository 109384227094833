.email-scheduling-mode-button {
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 8px 24px;
  border-radius: 4px 4px 0px 0px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.email-scheduling-mode-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.email-scheduling-mode-button-selected {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 24px;
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: 2px solid #007bff;
  background: #e9ecef;
  color: #007bff;
  cursor: default;
}

.email-scheduling-divider {
  border-bottom: 1px solid #dbdbdb;
}

.tab-content {
  animation: fadeIn 0.5s ease-in-out;
  background-color: #f1f1f1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tab-buttons {
  display: flex;
  gap: 16px;
}
