.predective-table .predective-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 90%;
}

.predective-body-content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* border-top: 1px solid #e7e7e7;
  padding: 5px 0px; */
}

.predective-table .predective-header h6,
.predective-table .predective-body-content p {
  font-size: 16px;
  width: 19%;
  margin: 0;
  line-height: initial;
}
.predective-table .predective-header h6:first-child,
.predective-table .predective-body-content p:first-child {
  width: 37%;
}
.predective-table .predective-header h6 span {
  width: 50%;
  display: block;
}
.predective-table .predective-body-content p {
  font-weight: 500;
}
.predictive-ordering-page .predective-table {
  background: white;
  padding: 15px;
  margin-top: 15px;
}
.predective-body-content.predective-sub-total {
  flex-direction: column;
  width: 90%;
  padding: 5px 0px;
}

.predective-body-content.predective-sub-total .predective-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.predective-body-content.predective-sub-total .predective-sub h6:first-child {
  width: 75%;
  text-align: right;
}

.predective-body-content.predective-sub-total .predective-sub:last-child {
  color: #69bef1;
}
.predective-body-content.predective-sub-total .predective-sub h6 {
  font-size: 14px;
  margin-bottom: 2px;
}
.predective-body-content.predective-sub-total .predective-sub:last-child h6 {
  font-size: 11px;
}
.right-predective-content .client-option.service-edit-icon button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.main-predective-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 5px 0px;
}

.main-predective-content .left-predective-content {
  width: 90%;
}

.main-predective-content .right-predective-content i {
  color: red;
  cursor: pointer;
}
.predective-table .predective-body-content p:last-child {
  text-align: right;
}
.right-predective-content {
  width: 10%;
  text-align: right;
}
/* .predective-table .predective-header h6:last-child{
  text-align: right;
} */
.predective-table .predective-header h6 {
  font-weight: 700;
}
.add-predictive-product .select-box:after {
  content: "";
  position: absolute;
  background-image: url("../../assets/home/arrowdown.png");
  background-position: center;
  background-repeat: no-repeat;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  opacity: 0.4;
  pointer-events: none;
  background-size: 18px;
  width: 20px;
  height: 20px;
}
.add-predictive-product .staff-form label.form-label {
  text-transform: initial;
}

/********* calcium supply popup ********/
.calcium--supply-popup h5 {
  margin-bottom: 20px;
}
.thankyou-calcium-popup h5 {
  text-align: center;
  font-size: 15px;
}
.select-box.removeSelectAfter::after {
  display: none !important;
}
.predective-body-content.predective-sub-total .predective-sub:first-child h6 {
  color: #0048ba;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .predective-table .predective-header h6 {
    font-size: 11px;
  }
  .predective-table .predective-body-content p {
    font-size: 11px;
  }
  .right-predective-content .service-edit-icon button img {
    width: 8px !important;
  }
  .predective-body-content.predective-sub-total .predective-sub {
    width: 100%;
  }

  .pool-setup.pool-set-up.predictive-ordering-page
    .main-predective-content
    .action
    span
    i {
    font-size: 17px;
  }
}
@media only screen and (min-width: 991px) {
  .predective-table .predective-header h6 {
    font-size: 20px;
  }
  .predective-table .predective-header h6:last-child {
    text-align: right;
  }
  .predective-table .predective-body-content p {
    font-size: 18px;
  }
  .predective-body-content {
    padding: 15px;
  }
  .predective-body-content.predective-sub-total
    .desktop-screen
    .predective-sub
    h6:first-child {
    font-size: 20px;
  }
  .predective-body-content.predective-sub-total .predective-sub:last-child h6 {
    font-size: 15px;
  }
  .predective-body-content.predective-sub-total .predective-sub:first-child h6 {
    font-size: 20px;
  }
}
