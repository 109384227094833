.invoices-head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.invoice-body {
  display: flex;
  justify-content: space-between;
}
.invoices-head h6 {
  font-size: 13px;
  padding-right: 15px;
  margin-bottom: 0px;
  font-weight: 700;
}
.invoice-body p {
  font-size: 12px;
  padding-right: 15px;
  margin-bottom: 0px;
  color: #a7a7a7;
  word-break: break-word;
}
.invoices-head h6:first-child,
.invoice-body p:first-child {
  color: #0077c0;
}

.invoices-head h6:first-child,
.invoice-body p:first-child {
  width: 15%;
}
.invoices-head h6:nth-child(2),
.invoice-body p:nth-child(2) {
  width: 20%;
}
.invoices-head h6:nth-child(3),
.invoice-body p:nth-child(3) {
  width: 25%;
}
.invoices-head h6:nth-child(4),
.invoice-body p:nth-child(4) {
  width: 25%;
}
.invoices-head h6:nth-child(5),
.invoice-body p:nth-child(5) {
  width: 15%;
}

.invoices-table-left {
  width: 95%;
}
.table-section {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}
.invoices-table-right {
  width: 5%;
  display: flex;
  justify-content: flex-end;
}
.invoices-table-right img {
  height: 20px;
  transform: rotate(-90deg);
  opacity: 0.5;
}
.invoices-list .pool-setup {
  display: block;
}
.invoices-list .main-titles-mob {
  display: flex !important;
}
.invoices-list .user-pagination {
  padding-top: 80px;
}

/***********invoice description ****************/
.head-des {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoices-detail-table h6 {
  color: #03b0f0;
  font-weight: 600;
  font-size: 13px;
}
.invoices-detail-table p {
  color: #03b0f0;
  font-weight: 500;
  font-size: 13px;
}
.body-description.labour-invoice-cost p {
  color: #0077c0;
  font-weight: 500;
  line-height: 16px;
  /* border-bottom: 1px solid #0077c0; */
  width: fit-content;
  font-size: 14px;
  text-decoration: underline;
}
.invoice-description {
  margin: 30px 0px;
}
.invoice-total .body-description h6 {
  text-align: right;
}

.invoice-total h6:first-child {
  width: 70%;
}
.invoice-total h6:last-child {
  width: 30%;
}
.body-description p {
  margin-bottom: 0px;
}
.invoice-total {
  margin: 15px 0px 0px 0px;
}
.labour-invoice-cost:first-child {
  margin-bottom: 10px;
}
.margin-cost-invoice {
  margin-bottom: 5px;
}
.invoices-detail-table .labour-cost {
  font-style: italic;
  color: #585858;
}
.invoices-detail-table h6 {
  margin-bottom: 0px;
}
h6.invoice-no {
  margin-bottom: 20px !important;
}
.invoices-detail-table {
  padding-top: 20px;
}
.body-desc {
  padding-top: 15px;
}
p.labour-cost {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 25px;
  margin-bottom: 20px !important;
}
.payment-detail h6,
.payment-detail p {
  color: #00b050;
  line-height: 16px;
}
.payment-detail .body-description {
  margin: 10px 0px;
}
.payment-detail .payment-heading {
  margin-bottom: 10px;
}

/************ search invoices *****************/

.target-heading.client-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
}
.search-invoice .target-heading.client-profile h3 {
  color: #0f0e0e;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
}
.search-invoice .target-heading.client-profile p {
  color: #8d8d8d;
  font-size: 17px;
  margin-top: 5px;
}
.search-invoces-box {
  background-color: #f0f4ff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.search-invoces-box h6 {
  font-size: 18px;
  font-weight: 700;
  color: #28b8f2;
}
.search-invoces-box .search-box.form-group label {
  height: auto;
  margin-bottom: 3px;
}
.search-invoces-box .search-invoice-btn {
  display: flex;
  justify-content: flex-end !important;
}
.search-invoces-box .view-btn.search-invoice-btn {
  margin-top: 0px;
  margin-bottom: 10px;
}
/* .search-invoces-box input[type="date"]::-webkit-inner-spin-button,
.search-invoces-box  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none; 
    
} */
/* .search-invoces-box input[type="date"]::after {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    right: 6px;
    background: url('../../assets//home/dateicon.png');
    pointer-events: all;
} */

.search-invoces-box input[type='date'] {
  padding: 5px !important;
}
.search-invoice .pool-chemistry-box {
  padding: 5px 12px 5px;
}
.pool-chemistry-box.appointment-service-modal .form-group:first-child label {
  color: #333;
}

.search-invoces-box input[type='date'] {
  position: relative;
  padding: 10px;
}

.search-invoces-box input[type='date']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

.search-invoces-box input[type='date']:before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background: url('../../assets//home/cal-icon.png');
  background-repeat: no-repeat;
  right: 5px;
  background-size: contain;
}
.new-invoice .appointment-view .appointment-date {
  background-color: transparent;
  padding: 13px 0px !important;
}
/* .quotes-page .search-container{
    padding: 0px !important;
} */
.quotes-page .search-title .client-profile h3 {
  font-size: 22px;
  /* color: #0f0e0e !important; */
  font-weight: 600;
}
.quotes-page .appointment-view .form-group label {
  color: #333 !important;
  font-size: 13px;
}
.quotes-client-details p {
  margin-bottom: 0px;
  line-height: initial;
  font-size: 14px;
  max-width: 130px;
}
.quotes-page .appointment-date h3 {
  color: #333;
}
.quotes-page .appointment-view .appointment-date {
  align-items: center;
}
.client-quotes-notes {
  background-color: #f2f2f2;
  padding: 5px;
  line-height: 15px;
}
.client-quotes-notes span {
  font-style: italic;
}
.quotes-page .appointment-total {
  width: 100%;
  margin-left: auto;
  margin: 10px 0px;
}
.quotes-page .appointment-total p {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: initial;
}
.quotes-page .appointment-total h6:first-child {
  color: #333 !important;
}
.quotes-page .appointment-view .search-title:last-child {
  margin-bottom: 10px;
}
.pending-quote {
  padding-top: 0px !important;
}
.quotes-page .appointment-view .appointment-date.pending-quote {
  padding-top: 0px !important;
}

.client-quotes .invoices-head h6:nth-child(2) {
  width: 15% !important;
}
.client-quotes .invoices-head h6:nth-child(3),
.client-quotes .invoice-body p:nth-child(3) {
  width: 23% !important;
}
.client-quotes .invoices-head h6:nth-child(4),
.client-quotes .invoice-body p:nth-child(4) {
  width: 35%;
}

.invoice-detail.search-invoice .table-section {
  border-bottom: none;
  margin-top: 30px;
}
.invoices-table.pool-chemistry-box .no-invoice {
  text-align: center;
  padding: 20px 0px;
  font-size: 15px;
  font-weight: 600;
}
.invoices-list .search-box.form-group .form-control {
  padding: 0px 12px 0px 12px;
  color: black !important;
}
.new-search-popup .search-items:before {
  margin-top: 0px;
}
.search-invoice span.plus-icon i {
  color: #0077c0;
}

.invoice-description .left-description {
  width: 60%;
}

.invoice-description .right-description {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.invoices-list .head-des .right-description {
  width: 50%;
  text-align: right;
}
.new-invoice .search-container .client-profile img {
  margin-right: 0px !important;
}

.preview-popup .goods-cost p:last-child {
  text-align: left;
}
/* .pool-set-up.invoices-list {
    overflow: hidden;
} */
.no-invoice {
  text-align: center;
  margin: 10px 0px;
}
.invoices-list .custom-datepicker {
  padding: 0px 12px 0px 12px;
  color: black !important;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
  border: 1px solid #e0e0e0;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.invoices-list .custom-datepicker::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background: url('../../assets//home/cal-icon.png');
  background-repeat: no-repeat;
  right: 5px;
  background-size: contain;
}
.invoice-detail.search-invoice .form-group label {
  color: #28b8f2 !important;
  font-weight: 600 !important;
}
.quotes-page .invoices-head h6 {
  font-size: 11px;
  padding-right: 5px;
  width: 25%;
  margin-bottom: 0px;
  font-weight: 700;
}
.invoice-body p {
  font-size: 12px;
  width: 15%;
  padding-right: 5px;
  margin-bottom: 0px;
  color: #a7a7a7;
}
.quotes-page .invoices-head h6:first-child {
  width: 10%;
}
.quotes-page .appointment-view .search-container {
  padding: 0px !important;
}
.quotes-page .invoice-body p h6 {
  margin-bottom: 7px;
  line-height: 6px;
  font-size: 12px;
  font-weight: 700;
}
.quotes-page .invoice-body p {
  line-height: initial;
}
.quotes-page .invoice-body {
  margin-top: 2px;
}
.quotes-page .invoices-head h6:first-child,
.quotes-page .invoice-body p:first-child {
  width: 12%;
}
.quotes-page .invoices-head h6:nth-child(3),
.quotes-page .invoice-body p:nth-child(3) {
  width: 35%;
}
.quotes-page .invoice-body p {
  font-size: 11px;
}
.preview-quote .tax-invoice {
  justify-content: flex-end;
}
.preview-quote .transform-component-module_wrapper__1_Fgj {
  width: 100%;
}
.preview-quote .transform-component-module_content__2jYgh {
  width: 100%;
  display: inline-block;
}
.preview-quote .col-md-12 {
  padding-left: 0px;
  padding-right: 0px;
}
.preview-quote .preview-invoice-main {
  padding: 12px 0px;
}
.preview-quote .invoices-bottom {
  margin-top: 0;
}
.preview-quote .tax-invoice {
  margin-bottom: 0px;
}
.preview-quote .quote-description h6 span:first-child {
  width: 25%;
}
.preview-quote .quote-description h6 span:last-child {
  width: 75%;
}
.preview-quote .quote-description h6 {
  display: flex;
  justify-content: flex-start;
  font-size: 8px;
  font-weight: 500;
  margin-bottom: 5px;
}
.preview-quote .quote-info h6 {
  font-size: 8px;
  margin: 16px 0px 16px;
  font-weight: 500;
}
.preview-quote .quote-description {
  margin-bottom: 20px;
}
.preview-quote .desc-text {
  margin-bottom: 35px;
}
.preview-quote .desc-text p {
  font-style: italic;
  font-size: 8px;
}
.preview-quote .footer-btn.view-btn button:first-child {
  background-color: transparent;
  border-color: #747070 !important;
  color: #747070 !important;
}
.preview-quote .quote-info {
  margin-top: 20px;
}
.preview-quote .quote-date {
  margin-top: 20px;
}
.preview-quote .cl-address {
  max-width: 100px;
}
.preview-quote .quote-info p {
  font-style: italic;
}

.preview-quote .tax-invoice {
  margin-bottom: 0px;
  width: 95%;
}
.saved-quote-listing .table-section:first-child {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #e4e4e4;
}
.quote-listing-color p.blue-date {
  margin-bottom: -5px;
  font-size: 12px;
  color: #0077c0;
}
.quote-listing-color p.green-date {
  margin-bottom: -5px;
  font-size: 12px;
  color: #0dbf13;
}
.quote-listing-color p.red-date {
  margin-bottom: -5px;
  font-size: 12px;
  color: red;
}
.quotes-page .pool-chemistry-box.appointment-service-modal {
  padding: 0px !important;
}
.quotes-page .pool-chemistry-box.appointment-service-modal .table-section {
  margin-top: 25px;
}
.quotes-page .search-quote-listing .form-group label {
  color: #28b8f2 !important;
  font-weight: 600 !important;
}
.search-invoice-bg .pending-water-test {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-invoice-bg .pending-water-test .open-date.client-notes {
  width: auto;
}

.search-invoice-bg {
  background: white;
  padding: 10px;
  border-radius: 5px;
}

.search-invoice-bg .pending-water-test h3 {
  color: #28b8f2;
}
.search-invoice-bg .target-heading.client-profile {
  margin: 0px;
  padding: 5px 0px;
}
.search-invoice-bg {
  margin: 10px 0px;
}
.quotes-status.pending {
  color: #0077c0;
}
.quotes-status.saved {
  color: #0dbf13 !important;
}
.preview-popup-new .form-group {
  width: 100%;
}
.preview-popup-new select {
  color: #a7a7a7 !important;
}
.preview-popup-new .form-control {
  color: #495057;
  border: 1px solid #d2d2d2;
}
.approved-green {
  color: #0dbf13;
}
.deleted-red {
  color: red;
}
.deleted-yellow {
  color: #f9d30d;
}

.voidPermission {
  display: flex;
  justify-content: flex-end !important;
}
.search-invoice .target-heading.client-profile h3,
.search-invoice .target-heading.client-profile p {
  color: #0070c0 !important;
}
.preview-popup-new .target-heading.client-profile {
  width: 100%;
}

.preview-popup-new .target-heading.client-profile label {
  margin-bottom: 5px;
}

.preview-quote-popup .add-appointment {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-content: center;
}
.preview-quote-popup .react-transform-wrapper,
.preview-quote-popup .react-transform-component,
.preview-quote-popup .react-pdf__Document,
.preview-quote-popup .react-pdf__message {
  height: 100%;
}
.preview-quote-popup .react-pdf__message {
  display: flex;
  justify-content: center;
  align-items: center;
}
.quotes-status.saved h6 {
  color: #0dbf13 !important;
}
.appointment-view.appointment-service-modal.savedQuote-page
  .invoices-table-left {
  width: 100%;
}
.saved-quote-listing .invoices-head h6:nth-child(4),
.saved-quote-listing .invoice-body p:nth-child(4) {
  width: 40%;
}
.client-quotes p.quote-desc {
  display: grid;
}
@media screen and (max-width: 360px) {
  .invoices-head h6 {
    font-size: 10px;
  }
  .invoice-body p {
    font-size: 10px;
    line-height: initial;
    margin-top: 5px;
  }
}
