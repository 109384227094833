.duration-picker.time-picker-modal .video-tutorial {
  max-width: 310px;
  width: 100%;
  max-height: calc(100vh - 190px);
  height: 100%;
  overflow: auto;
  padding: 20px 20px;
}
.video-tutorial .main-menu {
  color: #2618d8;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  margin-top: 10px;
}
.video-tutorial .submenu-item {
  font-size: 14px;
  padding-bottom: 0;
  color: #42dbef;
  margin-top: 5px;
  /* line-height: 24px; */
  font-weight: 600;
  line-height: initial;
  padding: 5px 0px;
}
.video-tutorial h6 {
  text-align: center;
  margin-top: 22px;
  font-size: 15px;
}
.viewUploadVedio > div {
  height: unset !important;
  display: flex;
}

.video-tutorial::-webkit-scrollbar {
  width: 0px;
}

.staff-tmp-btn {
  margin: 20px auto 0 !important;
}

/* Track */
.video-tutorial::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.video-tutorial::-webkit-scrollbar-thumb {
  background: #888;
}

.video-tutorial::-webkit-scrollbar,
.video-tutorial::-webkit-scrollbar-thumb:hover {
  width: 0px !important;
  height: 0px !important;
  opacity: 0 !important;
  visibility: hidden !important;
  display: none;
}

.video_upload {
  min-height: 250px;
}

.video_btn {
  font-size: 14px !important;
  padding: 10px 10px !important;
  line-height: 20px;
}

.video_btn-right {
  justify-content: flex-end !important;
}

.video_btn-right button {
  background: none !important;
  border: 1px solid green !important;
}
.video_upload {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: fit-content;
}

.video_uploadImg .upload-logo .form-group label {
  font-size: 15px;
  font-weight: 500;
}
.video_uploadImg .video_upload {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}
.upload_btn {
  color: green !important;
  font-size: 14px;
}
.video_uploadImg .time-keeper-popup.duration-popup {
  max-width: 310px;
  width: 100%;
}
.video_uploadImg .video_upload video {
  max-height: 250px;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}
.video_uploadImg .back {
  position: absolute;
  right: 20px;
  top: 20px;
}

.video_uploadImg .back i {
  font-size: 24px;
  color: #9a9696;
  cursor: pointer;
}
.duration-picker.time-picker-modal.video_uploadImg:before,
.time-picker-modal.time-popup.video_uploadImg::before {
  content: "";
  position: fixed;
  background-color: #131313a8;
  height: 100%;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 999999999 !important;
}
.video_uploadImg .time-keeper-popup.duration-popup {
  padding: 35px 20px 30px;
}
.video_uploadImg .time-keeper-popup .duration-popup video {
  width: 100%;
}

.video_heading h6 {
  color: #4fb8f2 !important;
}

.video_heading {
  text-align: center !important;
  padding-bottom: 15px;
  color: blue;
}
.video_uploadImg .template-btn.view-btn.single-btn {
  display: flex;
  justify-content: flex-end;
}
.video_upload {
  height: fit-content !important;
}

.video_box video {
  border: 2px solid rgb(69, 70, 69);
  border-radius: 4px;
  height: fit-content;
}
.video_box {
  width: 100%;
  border: 1px solid rgb(220, 217, 217);
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.template-btn {
  margin: 40px auto 0 !important;
}

.video-tutorial .back-popup i {
  position: absolute !important;
  top: 0 !important;
  right: -15px !important;
}

.btn_close {
  width: 100%;
  border: 1px solid #b1aeae;
  background: none;
  border-radius: 8px;
  padding: 7px;
  margin: 20px 0px;
  color: #b1aeae;
}
.video_uploadImg .btn-edit {
  background-color: transparent !important;
  border-color: #747070 !important;
  color: #747070 !important;
  border: 1px solid !important;
}
.video_uploadImg .btn-save {
  background: #1aa01d !important;
  border: 1px solid #1aa01d !important;
  color: white !important;
}
.video_uploadImg .btn-delete {
  background-color: transparent !important;
  border-color: red !important;
  color: red !important;
  border: 1px solid !important;
}
.video-tutorial .back {
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.video-tutorial .back i {
  font-size: 26px;
  color: #888888;
  cursor: pointer;
}
.video-tutorial ul {
  text-align: center;
}

.video-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

.video-loader .spinner-border {
  width: 45px !important;
  height: 45px !important;
  color: #4181e5;
}
.video-tutorial li {
  cursor: pointer;
}
.progress-section {
  margin: 15px 0 0px;
  position: relative;
}
.video_uploadImg .time-keeper-popup.duration-popup h4 {
  color: #0a23f2;
  text-align: center;
}

.fs_10 {
  font-size: 10px;
}
