.footer-container {
  width: 100%;
  padding: 30px 0;
}
.footer-lower {
  padding: 5px 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.footer-upper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer-logo {
  width: 200px;
}
.footer-mid {
  width: 100%;
}
ul.footer-nav {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}
ul.footer-nav li {
  position: relative;
  margin-bottom: 15px;
}
ul.footer-nav li:before {
  content: "";
  position: absolute;
  height: 70%;
  width: 1px;
  background-color: #949090;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2px;
}
ul.footer-nav li:first-child:before {
  content: none;
}
ul.footer-nav li > a {
  padding: 0 10px;
  text-decoration: none;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  transition: 0.3s all ease-in;
  color: #7e7e81;
}
.copyright {
  color: #848485;
  font-size: 12px;
}
ul.footer-nav li > a:hover {
  color: #56c1ff;
}
/* ul.footer-nav li > a span {
    border-bottom: 2px solid #2929ff;
} */
/* .footer-wrapper {
    position: fixed;
    bottom: 0;
    background-color: white;
} */
.sticky-footer {
  display: none;
}
.calendar img {
  height: 40px;
}

.sticky-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.calendar h6 {
  color: #57595d;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.sticky-footer {
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}
.footer-container {
  display: none;
}
.footer-wrapper .headroom {
  z-index: 99999999999 !important;
}

.calendar.activeUrl img:first-child {
  display: none;
}
.calendar img:nth-child(2) {
  display: none;
}
.activeUrl img:nth-child(2) {
  display: block !important;
  margin: 0px auto;
}
.calendar.activeUrl h6 {
  color: #0a23f2 !important;
}
/* body.today-footer .admin-wrapper.header-down .headroom {
    transform: translateY(0%) !important;
}
body.today-footer .footer-wrapper .headroom--unpinned {
    transform: translateY(0%) !important;
} */
.sticky-footer .calendar {
  position: relative;
}

.sticky-footer .calendar span {
  position: absolute;
  top: -3px;
  background: #ffc107;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 0px;
  color: white;
  font-size: 10px;
  max-height: 20px;
  max-width: 20px;
  height: 100%;
  width: 100%;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 991px) and (orientation: landscape) {
  .sticky-footer {
    padding: 8px 10px 10px !important;
  }
  .sticky-footer .calendar img {
    height: 26px;
  }
  .sticky-footer .calendar h6 {
    font-size: 14px;
  }
}
