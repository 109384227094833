.strip-box-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.strip-layout .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.strip-layout .title button {
  font-size: 12px !important;
  height: 34px;
  line-height: initial;
}
.strip-page .upload-img-calc {
  position: absolute;
  right: 17px;
  top: 1px;
  z-index: 99;
}
.strip-page .title {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
.strip-box-list .box,
.strip-list .box {
  width: 30px;
  height: 30px;
  /* background: #2b1b70; */
}
.strip-list .blank-box {
  width: 30px;
  height: 52px;
  border-left: 0.3px solid #cfcfcf;
  border-right: 0.3px solid #cfcfcf;
}
.strip-box-list .box {
  border-radius: 2px;
}
.strip-box-list input {
  width: 56px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #b6c2e2;
  border-radius: 4px;
  margin: 14.39px 4px 4px;
  text-align: center;
}
.strip-box-list .selected-box {
  border: 2px solid #000000;
  border-radius: 4px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strip-list h6 {
  line-height: 16px;
  text-align: center;
  margin-bottom: 0px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  padding: 1px 0px !important;
}
.strip-list.strip-column {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  min-height: 511px;
}
.left-strip-column .box.box1 {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.box.box1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.left-strip-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 32px;
  /* width: 15%; */
  width: 30px;
}
.right-strip-column {
  width: calc(100% - 30px);
  /* margin-left: 22px; */
  margin-left: 25px;
}
.strip-list {
  /* margin-bottom: 12px; */
  min-height: 82px;
}
.strip-page .title {
  margin-bottom: 15px;
}
.strip-list-one .box1 {
  background: #2b1b70;
}
.strip-list-one .box2 {
  background: #0f115f;
}
.strip-list-one .box3 {
  background: #2b1b70;
}
.strip-list-one .box4 {
  background: #2d0b61;
}
.strip-list-one .box5 {
  background: #661462;
}
.strip-list-one .box6 {
  background: #831372;
}

.strip-list-two .box1 {
  background: #ebf5c1;
}
.strip-list-two .box2 {
  background: #fcf280;
}
.strip-list-two .box3 {
  background: #e3f08c;
}
.strip-list-two .box4 {
  background: #c6df81;
}
.strip-list-two .box5 {
  background: #90bf76;
}
.strip-list-two .box6 {
  background: #72b880;
}

.strip-list-three .box1 {
  background: #fefff0;
}
.strip-list-three .box2 {
  background: #faf8a3;
}
.strip-list-three .box3 {
  background: #ccc1ae;
}
.strip-list-three .box4 {
  background: #9564a6;
}
.strip-list-three .box5 {
  background: #88428e;
}
.strip-list-three .box6 {
  background: #6a2a88;
}

.strip-list-four .box1 {
  background: #fc8741;
}
.strip-list-four .box2 {
  background: #ee8433;
}
.strip-list-four .box3 {
  background: #d84c23;
}
.strip-list-four .box4 {
  background: #ce3b21;
}
.strip-list-four .box5 {
  background: #d6332f;
}
.strip-list-four .box6 {
  background: #d73047;
}

.strip-list-five .box1 {
  background: #e3be20;
}
.strip-list-five .box2 {
  background: #c79a3d;
}
.strip-list-five .box3 {
  background: #7c7b3f;
}
.strip-list-five .box4 {
  background: #374e39;
}
.strip-list-five .box5 {
  background: #661462;
}
.strip-list-five .box6 {
  background: #223e3c;
}

.strip-list-six .box1 {
  background: #e0963f;
}
.strip-list-six .box2 {
  background: #ee8433;
}
.strip-list-six .box3 {
  background: #b74425;
}
.strip-list-six .box4 {
  background: #a0262e;
}
.strip-list-six .box5 {
  background: #9a2259;
}
.strip-list-six .box6 {
  background: #70175a;
}
.strip-box-list .choose-box {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.strip-box-list .number-value {
  text-align: center;
}

.strip-box-list .number-value p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: initial;
}
.strip-box-list .box1 {
  margin-top: 10px;
}
.strip-layout {
  max-width: 375px;
}
.left-strip-column .blank-box:last-child {
  height: 70px;
  border-bottom: 0.3px solid #cfcfcf;
}
.left-strip-column .blank-box:first-child {
  height: 4px;
  border-top: 0.3px solid #cfcfcf;
}
.strip-page.four-strip-page .title {
  margin-bottom: 45px !important;
}
.strip-page .calculate-btn.draining-btn button {
  float: none !important;
  padding: 7px 12px !important;
  max-width: fit-content !important;
}
.strip-page .back-btn {
  max-width: 78px;
  min-width: 78px;
}
.strip-page .calculate-btn button {
  min-width: 105px;
  padding: 8px 12px;
}
.strip-page .calculate-btn {
  width: fit-content;
  min-width: fit-content !important;
}
.strip-page .calc-btn-reset .back-btn .btn {
  padding: 7px 12px;
  justify-content: center;
}

/* new strip css */
.strip-list h6 {
  margin-left: 4px;
}
.strip-page .calculate-btn img {
  height: 15px;
}
@media screen and (min-width: 480px) {
  .strip-layout {
    margin: 0px auto;
    padding: 30px 50px;
    gap: 20px;
    max-width: 563px;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .strip-page .title {
    width: 100%;
    max-width: 563px;
    text-align: center;
    margin: 0px auto;
  }
  .strip-page .calc-btn-bottom.form-row {
    margin: 0px auto;
    padding: 30px 50px;
    gap: 20px;
    max-width: 563px;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 0px;
  }
  .strip-page .calculate-btn,
  .strip-page .back-btn {
    padding-top: 0px;
  }
  .strip-box-list .box,
  .strip-list .box {
    width: 40px;
    height: 40px;
  }
  .strip-box-list .choose-box {
    height: 48px;
    width: 48px;
  }
  .strip-list .blank-box {
    width: 40px;
    height: 59px;
  }
  .strip-list {
    margin-bottom: 17px;
  }
  .strip-box-list input {
    height: 40px;
  }
  .strip-page .title h3 {
    font-weight: 600;
    font-size: 36px !important;
    line-height: 48px;
    color: #000000;
  }
  .strip-page .title {
    margin-bottom: 40px !important;
    margin-top: 40px !important;
  }
  .calculator-main .admin-container {
    /* z-index: -1; */
    position: relative;
  }
}
@media screen and (max-width: 991px) {
  .strip-page.four-strip-page .title {
    margin-top: 20px !important;
  }
  .four-strip-page .strip-list.strip-column {
    min-height: 491px;
  }
  .four-strip-page .blank-box:last-child {
    height: 150px;
  }
}
@media screen and (max-width: 480px) {
  .strip-layout {
    margin-left: auto;
  }
}
@media screen and (max-width: 360px) {
  .strip-box-list .box,
  .strip-list .box {
    height: 26px;
    width: 26px;
  }
  .strip-box-list .choose-box {
    height: 34px;
    width: 34px;
  }
  .strip-box-list input {
    width: 50px;
    height: 26px;
  }
  .strip-list .blank-box {
    width: 26px;
    height: 56px;
  }
}

@media screen and (min-width: 991px) {
  .ml-auto.calc-btn-reset.desktop-bt-show {
    justify-content: space-between;
  }
  .strip-page .back-btn {
    max-width: 100px;
    min-width: 100px;
  }
  .strip-page.content-container .back-btn button {
    width: fit-content !important;
    min-width: 100% !important;
  }
  .strip-page .title {
    text-align: left;
  }
  .strip-page .title h3 {
    font-size: 25px !important;
  }
  .strip-page .title {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
  }
  .strip-page .calculate-btn img {
    margin-right: 5px;
  }
}
@media screen and (max-width: 340px) {
  .strip-page .calculate-btn button {
    min-width: 85px;
    padding: 8px 12px;
  }
  .calculate-btn .btn {
    font-size: 10px;
  }
}
