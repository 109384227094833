.main-content {
  padding-top: 161px;
}
.homepage .main-content {
  padding-top: 0;
}
.banner-container {
  background: url("../../assets//home/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-position: top center;
  position: relative;
}
.banner-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
  padding-top: 20%;
}
.banner-logo {
  display: none;
}
.banner-content h1 {
  font-size: 60px;
  text-align: center;
  color: #fff;
}
.content-container {
  padding: 100px 0;
}
.block-btn {
  background: #fff;
  border: 2px solid #dcdcdc;
  padding: 20px 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  width: 100%;
  font-weight: 700;
  transition: 0.3s all ease-in;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}
.block-btn:hover {
  box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.3);
}
.block-btn:hover a {
  color: #333;
}
.home-block-container .block-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;
}
.home-block-container .block-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  padding-top: 0;
}
/* .sep-container{
    background: url("../../assets//home/testimonial-bg.jpg");
    width: 100%;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position:relative;
}
.sep-container:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(5, 80, 148, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
} */
.service-heading {
  text-align: center;
  margin-bottom: 42px;
}
.service-heading h3 {
  font-size: 40px;
  margin-bottom: 0;
}
.service-box {
  width: 100%;
  height: 120px;
  border: 2px solid #dcdcdc;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s all ease-in;
  text-align: center;
  border-radius: 10px;
}
.service-box:hover {
  box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.3);
}
.service-box:hover a {
  color: #333;
}

.testimonial-container {
  background: url("../../assets//home/testimonial-bg.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
}
.testimonial-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(5, 80, 148, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.testimonial-slider {
  padding: 0 40px;
}
.testimonial-content {
  background-color: transparent;
  color: #fff;
  text-align: center;
  font-style: italic;
  font-size: 20px;
  line-height: 35px;
  padding: 0 30px;
  text-shadow:
    2px 2px 2px rgba(0, 0, 0, 0.3),
    2px 2px 10px rgba(0, 0, 0, 0.4),
    2px 2px 20px rgba(0, 0, 0, 0.7),
    2px 2px 30px rgba(0, 0, 0, 0.5);
}
.testimonial-content span {
  font-weight: 600;
}
.content-container.cta-container {
  background-color: #f8d400;
  padding: 0;
}
.cta-box {
  padding: 60px 20px;
  display: flex;
  align-items: center;
  color: #000;
}
.cta-box h4 {
  margin-bottom: 0;
}
.cta-btn .btn {
  width: 200px;
}
.cb-box {
  height: 100%;
  width: 100%;
  position: relative;
  border: 1px solid hsl(0deg 0% 0% / 20%);
  border-radius: 10px;
  overflow: hidden;
}

.cb-img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.cb-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cb-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.cb-btn {
  position: absolute;
  z-index: 9;
  padding: 15px 12px;
  border: 2px solid #f8d400;
  top: 15px;
  left: 15px;
  font-size: 16px;
  font-weight: 700;
  background: #f8d400;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s all ease-in;
  border-radius: 10px;
}
.cb-btn:hover {
  letter-spacing: 1px;
  background-color: rgb(0, 0, 0, 0.6);
  color: #fff;
}
.cb-btn > a {
  color: #000;
  transition: 0.3s all ease-in;
}
.cb-btn:hover a {
  color: #fff;
}
.sati-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content-container.sati-container {
  padding: 50px 0;
}
.sati-logo {
  width: 300px;
  margin: 0 auto;
}
.home-main-page .admin-content {
  padding-bottom: 0px;
}

.home-main-page .admin-content {
  padding-top: 0px;
}
html.calendar-main-page.home-main-page body.fixed-body-section {
  overflow: hidden !important;
  position: fixed !important;
}
