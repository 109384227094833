.contact-container .title h3 {
  margin-bottom: 40px;
}
.contact-container .title {
  font-size: 20px;
  line-height: 30px;
}
.contact-container .title > p > a {
  font-weight: 700;
}
.contact-form {
  padding: 50px 0 20px;
}
.content-container {
  padding: 60px 0;
}
.contact-form .form-control {
  height: 50px;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  background: #f5f5f5;
  border-color: #a6a6a6;
  box-shadow: none;
}
.contact-form .form-control::placeholder {
  color: rgb(0, 0, 0, 0.5);
}
.contact-form .form-group {
  margin-bottom: 10px;
}
.contact-form textarea.form-control {
  height: 150px;
  resize: none;
}
.submit-btn.form-group .btn {
  width: 100%;
  background: #000;
  color: #fff;
  height: 50px;
  border-color: #000;
}
.submit-btn.form-group .btn:hover,
.submit-btn.form-group .btn:focus {
  background: rgb(0, 0, 0, 0.8);
  color: #fff;
  border-color: #000;
}
.contact-container.client-main-contact .title p {
  color: #3699db;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: initial;
}
.contact-form.staff-form input {
  background-color: white;
  height: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.contact-form.staff-form textarea {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 100%;
}
.contact-form .form-group label span {
  color: #f50404;
  font-size: 18px;
  padding-left: 4px;
}
.contact-page .view-btn {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 991px) {
  .contact-page.pool-set-up .main-titles-mob {
    display: block;
  }
  .contact-page.pool-set-up .client-main-contact {
    border-radius: 8px;
    padding: 25px;
  }
  .contact-page .contact-form .view-btn {
    justify-content: flex-end;
  }
  .contact-form.staff-form input {
    height: 40px;
    font-size: 14px;
  }
  .contact-page.pool-set-up .staff-form label.form-label {
    line-height: initial;
    height: auto;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .contact-page .staff-form textarea.form-control {
    font-size: 15px;
  }
  .contact-container.client-main-contact .title p {
    font-size: 16px;
  }
  .contact-page .main-titles-mob h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #000;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
  }
  .contact-page .main-titles-mob {
    margin: 0;
  }
  .contact-page .contact-container.client-main-contact {
    margin-top: 56px;
  }
  .contact-form.staff-form input:focus {
    border: 1px solid #e0e0e0 !important;
  }
  .profile-new-form {
    padding: 40px 0;
  }
  .profile-new-form .view-btn {
    max-width: 500px;
    margin: 0 auto;
  }
  .profile-new-form .dropdown-toggle::after {
    display: none;
  }
  .profile-new-form .form-check-label {
    padding-left: 5px;
  }
}
@media screen and (max-width: 991px) {
  .profile-new-form .dropdown-toggle::after {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .profile-new-form .view-btn {
    justify-content: space-between !important;
  }
  .profile-new-form .dropdown-toggle::after {
    display: none;
  }
}
