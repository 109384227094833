/* styles.css */
.form-inline {
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 0.5rem; /* Add some space between form elements */
  flex-wrap: wrap; /* Allow items to wrap to new lines on smaller screens */
}

.schedule-list {
  height: calc(100vh - 400px);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.schedule-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

/* Modal */
.email-scheduling-modal-container {
  padding: 16px 16px !important;
}

.email-scheduling-modal-container .modal-content .close {
  padding: 0px;
  margin: 0;
  width: max-content;
  height: max-content;
}

.email-scheduling-modal-container .modal-content .close::after {
  top: 0px;
  right: -4px;
  width: 14px;
}

.email-scheduling-modal-container .modal-content {
  padding: 16px 8px 0px !important;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-inline {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: stretch; /* Stretch items to full width */
  }
}

@media (max-width: 576px) {
  .schedule-list {
    height: calc(100vh - 428px);
  }
}
