.edit-chemical-popup .chemical-table-head {
  /* background-color: black; */
  color: white;
  display: flex;
  justify-content: space-between;
}
.edit-chemical-popup .chemical-table-head h6,
.edit-chemical-popup .chemical-table-body h6 {
  width: 20%;
  margin-bottom: 0px;
  border: 0.5px solid #d0c9c9;
  padding: 5px 5px;
}
.edit-chemical-popup .chemical-table-head h6:first-child,
.edit-chemical-popup .chemical-table-body h6:first-child {
  width: 72%;
}
.edit-chemical-popup .chemical-table-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.edit-chemical-popup .chemical-table-body h6:first-child {
  font-style: italic;
  background-color: #ffd62a;
}
.edit-chemical-popup .chemical-table-body h6 {
  padding: 5px 5px;
  border-bottom: none;
  border-right: none;
  min-height: 42px;
}
.edit-chemical-popup .chemical-table-body h6:last-child {
  border-right: 0.5px solid #d0c9c9;
}
.edit-chemical-popup .chemical-table-body:last-child h6 {
  border-bottom: 0.5px solid #d0c9c9;
}
.edit-chemical-popup .chemical-table-body h6 input {
  background-color: white;
  padding: 4px 5px !important;
  margin: 2px 5px;
  width: auto;
  border: 1px solid #adadad;
  border-radius: 5px;
  max-width: 40px;
  text-align: right;
}
.edit-chemical-popup .chemical-table-body h6 {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-chemical-popup .chemical-table-body h6:first-child {
  justify-content: flex-start;
}
.edit-chemical-popup .view-btn {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.edit-chemical-popup .view-btn button {
  width: 48%;
}
.edit-chemical-popup .bottom-text {
  margin-top: 10px;
  text-align: right;
}
.edit-chemical-popup .bottom-text p {
  font-weight: 600 !important;
}
.edit-chemical-popup .chemical-table-body:last-child h6 {
  border-bottom: 0.5px solid #d0c9c9;
}
.edit-chemical-popup .bottom-text p:first-child {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: -5px;
}
.edit-chemical-popup .chemical-table-head h6 {
  text-transform: uppercase;
}
.edit-chemical-popup .chemical-table-body:last-child h6:first-child {
  text-align: right;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #777171;
}
.edit-chemical-sellprice .chemical-table-body:last-child h6:first-child {
  text-align: right;
  justify-content: flex-end;
}
.edit-chemical-popup .chemical-table-body:last-child h6 {
  color: #777171;
}
.edit-chemical-sellprice h6:nth-child(3) {
  width: 25% !important;
}

.edit-chemical-sellprice h6:nth-child(2) {
  width: 25% !important;
}
.edit-chemical-sellprice h6:nth-child(1) {
  width: 75% !important;
}
.edit-chemical-sellprice .chemical-table-body h6 input {
  background-color: white;
  padding: 4px 5px !important;
  margin: 2px 0px 2px 5px;
  width: auto;
  border: 1px solid #adadad;
  border-radius: 5px;
  min-width: 40px !important;
  max-width: 100%;
  text-align: right;
}
.edit-chemical-popup .chemical-table-head h6 {
  border: 1px solid black;
}

.edit-chemical-popup .chemical-table-body h6.edit-chemical-action {
  text-align: center;
}
.edit-chemical-popup .chemical-table-body h6.edit-chemical-action .fa-times {
  color: red;
  font-size: 16px;
  /* margin: auto; */
}
/* .edit-chemical-popup .chemical-table-body.subtotal h6:last-child, .edit-chemical-popup .chemical-table-body.subtotal h6:nth-child(2) {
    width: 30%;
} */
/* .edit-chemical-sellprice .chemical-table-body.subtotal h6:last-child, .edit-chemical-sellprice .chemical-table-body.subtotal h6:nth-child(2) {
    width: 18%;
} */
span.hide-text {
  display: none;
}
.edit-chem-popup .chemical-table-body.subtotal h6:first-child {
  background: transparent;
}
.edit-chem-popup .chemical-table-body h6.edit-chemical-action {
  border: none;
  border-left: 0.5px solid #d0c9c9;
  text-align: right;
  justify-content: flex-end;
  width: 8%;
}

.edit-chem-popup .chemical-table-head h6:last-child {
  width: 8%;
  background: transparent;
  border: none;
  border-left: 1px solid black;
}
.edit-chem-popup .chemical-table-body.subtotal h6:last-child {
  width: 8%;
  border: none;
  border-left: 0.5px solid #d0c9c9;
}
.edit-chem-popup .chemical-table-head h6 {
  background-color: black;
}
.edit-chemical-sellprice .chemical-table-head {
  background-color: black;
}
.edit-chemical-popup .chemical-table-body.subtotal h6:first-child {
  background: transparent;
}
@media only screen and (min-width: 991px) {
  button.btn.btn-edit.revert-desktop.btn.btn-primary {
    max-width: 180px !important;
    margin-left: 3px;
  }
  button.btn.btn-save.revert-desktop.btn.btn-primary {
    max-width: 180px !important;
  }
  .view-appointment-popup .modal-header {
    margin-left: -12px;
  }
}
