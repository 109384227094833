.temp-popup .temp-heading {
  text-align: center;
  margin-bottom: 20px;
}

.temp-popup .temp-heading h4 {
  margin: 0;
}

.temp-popup .temp-heading p {
  font-style: italic;
  color: #25c2ff;
  font-size: 12px;
  font-weight: 500;
}
.temp-popup .appointment-view {
  padding: 0px 12px 0px;
}
.temp-popup .temp-input {
  margin: 20px auto 0;
}

.temp-popup .temp-input .form-group {
  max-width: 155px;
  margin: 0px auto;
  position: relative;
}

.temp-popup .temp-input span {
  position: absolute;
  right: 12px;
  top: 3px;
}
