.filter-date .react-datepicker-wrapper input {
	border-color: #ddd;
	background: transparent;
	border: 2px solid #ddd;
	color: #808080;
	border-radius: 10px;
	height: 40px;
	width: 100%;
	min-width: 180px;
	padding: 8px 55px 8px 12px;
	font-weight: 500;
	cursor: pointer;
}

.react-datepicker__input-container:after {
	background-size: 40px;
	top: 0px;
	right: 0px;
	z-index: 0 !important;
}

.filter-date {
	display: flex;
	align-items: center;
}
.filter-date span {
	padding: 0 15px;
}

.close-button {
	background-color: transparent !important;
	border: 2px solid #939191 !important;
	color: #939191 !important;
	height: 40px;
	padding: 2px;
}

.table-responsive {
	max-height: 460px !important;
	width: calc(100vw - 50px) !important;
}

p {
	margin-bottom: 6px !important;
}

.react-datepicker-popper {
	z-index: 11;
}

.fade-loader {
	left: 50% !important;
	margin-top: 10% !important;
	display: inherit;
	position: relative;
	font-size: 0px;
	width: 60px;
	height: 60px;
}
