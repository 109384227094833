.up-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-box {
  max-width: 650px;
  width: 100%;
  background: #fff;
  padding: 40px 80px;
  border-radius: 10px;
  /* border: 1px solid #ced4da; */
  box-shadow: 0 0 20px rgb(0, 0, 0, 0.1);
}
.update-title {
  text-align: center;
  margin-bottom: 30px;
}
.update-title h3 {
  font-size: 28px;
  margin-bottom: 0;
  font-family: "Exo 2", sans-serif;
}
.update-form .form-group {
  margin-bottom: 15px;
}
.update-form .form-label {
  font-size: 16px;
  color: #000;
}
.update-form .form-control {
  height: 50px;
  font-size: 16px;
  background: #fff;
}
.update-form .submit-btn {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
.update-form .submit-btn .btn-primary {
  color: #0077c0;
  background-color: #fff;
  border-color: #0077c0;
  width: 100%;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
}
.login-popup-btn.update-password {
  margin-top: 25px;
}

.new_update_pasd {
  justify-content: space-between;
}
