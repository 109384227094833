.chatbot-settings-form-container {
  padding: 16px 0px 0px;
}

.chatbot-settings-mode-button {
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 4px 32px;
  border-radius: 4px 4px 0px 0px;
}

.chatbot-settings-mode-button-selected {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 32px;
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: 1px solid #888686;
  background: #0000000f;
}

.chatbot-settings-divider {
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  display: block;
}

.chatbot-settings-form-sub-container {
  display: flex;
  flex-direction: column;
  gap: 16;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.chatbot-settings-form-sub-title {
  font-size: 18px;
  font-weight: 500;
}

.chatbot-settings-form-label {
  font-size: 14px;
  font-weight: 500;
}

.btn-avatar-upload {
  background: transparent;
  border: none;
}

.bot-avatar-image {
  border: 1px solid #ccc;
  border-radius: 50%;
}

.chatbot-setting-save-btn {
  /* background: transparent; */
  /* border: 1px solid #ccc; */
  padding: 4px 16px;
  border-radius: 6px;
}

/* Modal */
.chatbot-settings-modal-container .modal-content {
  padding: 16px 8px 0px !important;
  height: max-content;
  min-height: max-content;
  border-radius: 8px;
}

.chatbot-settings-modal-container {
  height: max-content;
  padding: 0px 16px !important;
}

.chatbot-settings-modal-container .modal-content .close {
  padding: 0px;
  margin: 0;
  width: max-content;
  height: max-content;
}

.chatbot-settings-modal-container .modal-content .close::after {
  top: 0px;
  right: -4px;
  width: 14px;
}

/* Remove Modal */

.chatbot-settings-remove-modal-container .modal-content {
  padding: 16px 8px 0px !important;
  height: max-content;
  min-height: max-content;
  border-radius: 8px;
  width: 400px;
  min-width: 320px;
}

.chatbot-settings-remove-modal-container {
  height: max-content;
  padding: 0px 16px !important;
  display: flex;
  justify-content: center;
}

.chatbot-settings-remove-modal-container .modal-content .close {
  padding: 0px;
  margin: 0;
  width: max-content;
  height: max-content;
}

.chatbot-settings-remove-modal-container .modal-content .close::after {
  top: 0px;
  right: -4px;
  width: 14px;
}

.custom-modal-height {
  height: 80vh; /* Adjust this value to whatever height you prefer */
}

.custom-modal-body {
  max-height: calc(80vh - 100px); /* Subtract header and footer heights */
  overflow-y: auto;
}

/* Modal */

/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 4px;
}

/* Handle (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #47474785; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Hover state */
::-webkit-scrollbar-thumb:hover {
  background: #474747a9; /* Darker when hovered */
}
