@media screen and (min-width: 991px) {
  .new-cal {
    padding-top: 25px;
  }
  .new-cal .fc .fc-view-harness {
    z-index: 0;
  }
  .new-cal .calender-date-section {
    top: 100px;
  }
  .new-cal .calender-date-section {
    z-index: auto !important;
  }
  .new-cal .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: 95px !important;
  }

  .event-popup-btn.footer-btn.view-btn {
    margin-bottom: 10px;
  }
  .time-keeper-popup.duration-popup.event-popup {
    max-width: 450px;
    padding: 25px 12px;
  }
}
