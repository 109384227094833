@media (min-width: 991px) {
  .inventory-page .main-titles-mob .content-dropdown {
    display: none;
  }
  .inventory-page .main-titles-mob h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #000;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
  }

  /* Inventory Product  List & Non Inventory Product List */
  .inventory-page .table-data .service-mbl {
    display: block;
  }
  .inventory-page .service-container .service-title.title-service,
  .inventory-page .target-heading {
    display: flex;
  }
  .inventory-page .service-container .service-title.title-service h4 button,
  .inventory-page .target-heading h6 {
    font-size: 20px;
    padding: 0;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
  }

  .inventory-list
    .table-data
    .inventory-list
    .table-data
    .accordion
    .product-service-list {
    align-items: center;
  }

  .product-service-container.inventory-list .mob-table .heading-accordion h5 {
    font-size: 18px;
  }
  .product-service-container.inventory-list .mob-table .heading-accordion h5 i {
    font-size: 20px;
  }
  .product-service-container.inventory-list
    .table-data
    .outer-accordion.accordion
    .card
    .sub-category
    button
    i {
    font-size: 18px;
  }
  .product-service-container.inventory-list
    .table-data
    .outer-accordion.accordion
    .card
    .sub-category
    span,
  .product-service-container.inventory-list
    .table-data
    .outer-accordion.accordion
    .card
    .time-category
    .timehour {
    font-size: 16px;
  }

  .product-service-container.inventory-list
    .table-data
    .mob-table
    .card-header
    .heading-accordion {
    padding: 0;
  }

  /* Inventory Search & Predictive Ordering Summary */
  .inventory-page .search-inventory .appointment-service-modal {
    padding: 20px;
  }
  .inventory-page .search-inventory .appointment-service-modal h5 {
    padding: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* text-transform: uppercase; */
    color: #333;
  }

  .search-invoice.search-inventory
    .appointment-service-modal
    .search-invoces-box
    h6 {
    color: #333 !important;
    margin: 0 0 20px;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .outstandings-invoices {
    padding: 0px 10px;
    margin-bottom: 20px;
    height: 34px !important;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .outstandings-invoices
    p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* color: #333333; */
  }
  .search-invoice.search-inventory .appointment-service-modal .form-group {
    margin: 0;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .form-group
    label {
    height: fit-content !important;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    margin-bottom: 10px;
    color: #333 !important;
    font-weight: 600 !important;
    margin: 0;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .form-group
    .select-box {
    padding: 0;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .form-group
    .form-control {
    margin: 20px 0;
    /* padding: 0px 10px; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
  }
  .search-invoice.search-inventory .appointment-service-modal p.group-time {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .search-invoice.search-inventory
    .appointment-service-modal
    .view-btn.search-invoice-btn {
    padding: 0;
    height: 34px !important;
  }

  .InventorySearch-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .InventorySearch-form .form-group {
    width: calc(50% - 10px);
    align-self: flex-end;
  }
  .searchInner {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
  }
  .searchInner .outstandings-invoices {
    width: 100%;
  }
  .InventorySearch-form .form-group .form-group {
    width: 100%;
  }

  /* Inventry Adjustment */
  .inventory-adjustment .appointment-summary {
    padding: 20px;
  }
  .inventory-adjustment .appointment-summary .form-group {
    margin: 0 0 20px;
  }
  .inventory-adjustment .appointment-summary .form-group label {
    height: fit-content !important;
    padding: 0;
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .inventory-adjustment .appointment-summary .search-box .form-group img {
    top: 60px;
  }
  .inventory-adjustment
    .appointment-summary
    .search-box
    .form-group
    .form-control {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .inventory-adjustment .view-btn {
    margin: 0 !important;
    padding: 0 !important;
  }
  .inventory-adjustment .view-btn button.btn-edit {
    margin: 0;
  }

  .inventory-page .client-option.service-edit-icon .dropdown-menu {
    min-width: 11rem !important;
  }
  .inventory-page .client-option.service-edit-icon .dropdown-menu .action {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}
@media (max-width: 991px) {
  .inventory-page .main-titles-mob .main-titles-desktop {
    display: none;
  }
}
