@media screen and (min-width: 991px) {
  .calc-btn-reset .calculate-btn {
    margin-left: initial;
    min-width: fit-content !important;
  }

  .calc-btn-reset button {
    min-width: 150px !important;
    text-align: center;
    justify-content: center !important;
  }
  .calc-btn-reset {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 20px;
  }
  .event-popup-btn.footer-btn.view-btn {
    justify-content: center;
    gap: 20px;
  }
}
