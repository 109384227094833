.unsubscribe-container {
  background: #e0e8f5;
  min-height: 100vh; /* Use min-height for better mobile responsiveness */
  display: flex;
  padding: 32px 16px;
  align-items: flex-start;
  justify-content: center;
}

.unsubscribe {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;
}

.logo-unsubscribe {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.logo-unsubscribe img {
  width: 150px; /* Increased width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the logo scales properly */
  margin: 0 auto; /* Center the logo */
  display: block; /* Center the logo */
}

.unsubscribe-title {
  font-size: 22px;
  margin-bottom: 16px;
  font-family: 'Exo 2', sans-serif;
  text-align: center;
}

.unsubscribe-form {
  margin-top: 20px;
}

.unsubscribe-form label.form-label {
  color: #171717;
  font-size: 18px;
  font-weight: 600;
}

.unsubscribe-form .form-control {
  color: #333;
  border: 1px solid #e0dede;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.unsubscribe-popup-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.unsubscribe-popup-btn .btn {
  border-radius: 10px;
  padding: 8px 18px;
  font-size: 16px;
  width: 48%;
  transition: background-color 0.3s ease;
}

.unsubscribe-popup-btn .btn-primary,
.unsubscribe-popup-btn .btn-primary:hover,
.unsubscribe-popup-btn .btn-primary:focus {
  color: #fff;
  background-color: #1aa01d;
  border-color: #1aa01d;
  box-shadow: none;
}
.unsubscribe-popup-btn .btn-primary:not(:disabled):not(.disabled).active,
.unsubscribe-popup-btn .btn-primary:not(:disabled):not(.disabled):active,
.unsubscribe-popup-btn .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1aa01d !important;
  border-color: #1aa01d !important;
  box-shadow: none !important;
}

.unsubscribe-popup-btn .btn-edit {
  background-color: transparent;
  border-color: #747070;
  color: #747070;
}

.unsubscribe-popup-btn .btn-edit:hover {
  background-color: #e0e0e0;
}

.feedback-content-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3rem;
  margin: 0;
}
.final-content-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3rem;
  margin: 0;
}

.feedback-subtitle {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.feedback-footer-text {
  color: #8d8d8d;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: normal;
}

.feedback-footer-text a {
  text-decoration: none;
  color: #0a23f2;
  cursor: pointer;
}

.custom-checkbox .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
}

.custom-checkbox .form-check-input:checked {
  background-color: #5bd125;
  background-image: url(../../assets/home/check1.png);
  background-size: cover;
  border: none !important;
}

.expired-text h1,
h4 {
  font-weight: 400;
}
