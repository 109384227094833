.no-data {
  display: flex;
  justify-content: center;
  min-height: 200px;
  align-items: center;
  width: 100%;
  color: #fff;
}
.no-data h6 {
  font-size: 24px;
  font-weight: 500;
  color: #0a23f2 !important;
}
