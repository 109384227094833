.subscription_billing {
  padding-right: 33px;
}

.subscription_billing .billing-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
}

.subscription_billing .billing-box h3 {
  width: 55%;
  font-size: 13px;
}
.subscription_billing h5 {
  width: 45%;
  font-size: 13px;
  color: #7c7c7c;
  line-height: 17px;
  font-weight: 400;
  text-align: left;
  padding: 0 10px;
  font-family: 'Exo 2' !important;
  word-wrap: break-word;
}
.payments-billing p {
  min-width: 80px;
  font-size: 18px;
  width: 100%;
  color: #949496;
  margin: 0;
  word-wrap: break-word;
}

.payments-billing h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3c424b;
}

.data_heading {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #3c424b;
  padding: 0px 33px;
}
.cancel_data p {
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
}

.cancel_data a {
  color: #007bff;
  text-decoration: underline;
}
.btn-back {
  background: grey !important;
}

.sucessPopup .modal-content {
  height: 300px !important;
}
.crossBtn-Popup {
  position: relative;
}

.crossBtn-Popup::before {
  content: ' ';
  position: absolute;
  height: 15px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/home/close-cross-gray.png');
}

.text-normal {
  text-transform: none !important;
}

@media screen and (max-width: 767px) {
  .payments-billing h6:nth-child(1),
  .payments-billing p:nth-child(1) {
    max-width: 60px;
    color: #0096ee;
  }

  .payments-billing h6 {
    font-size: 13px;
  }

  .payments-billing p {
    font-size: 13px;
    min-width: 50px;
  }
  .data_heading {
    font-size: 14px;
  }
  .payments-billing {
    border-bottom: 1px solid #dadfe7;
  }

  .sucessPopup {
    display: flex !important;
    justify-content: center;
  }

  .sucessPopup .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sucessPopup .modal-content {
    border-radius: 12px !important;
    max-width: 320px !important;
    width: 100% !important;
    min-height: 300px !important;
    /* height: 100% !important; */
    padding: 20px 30px 10px !important;
  }
  .text-normal {
    text-transform: none !important;
  }
}

@media screen and (max-width: 424px) {
  .payments-billing h6:nth-child(1),
  .payments-billing p:nth-child(1) {
    max-width: 30px;
    color: #0096ee;
  }
  .payments-billing h6 {
    font-size: 12px;
  }

  .payments-billing p {
    font-size: 12px;
    min-width: 50px;
  }
  .text-normal {
    text-transform: none !important;
  }
  .cancele-subscription-btn {
    font-size: 12px !important;
  }
}

.cancele-subscription-btn .btn {
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
}
