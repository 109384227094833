.billing-content {
  background: white;
  padding: 12px;
  border-radius: 5px;
}

.billing-content .my-details-section li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
}

.newContent_popup {
  height: 600px;
  max-width: 420px;
  width: 100%;
  overflow-y: scroll;
}

.cross-popup {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  text-align: right;
}
.btnWhite {
  color: white !important;
}

.billing-content ul li p {
  margin: 0;
}
.billing-content ul li h3 {
  font-size: 15px;
  width: 60%;
  font-weight: 600;
  margin: 0;
}

.billing-content ul li h5 {
  font-size: 14px;
  width: 40%;
  word-wrap: break-word;
  font-weight: 500;
  margin: 0;
}

.billing-content h4 {
  color: #0048ba;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.billing-content ul {
  padding-bottom: 15px;
}
.billing-popup .sub-total {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}
.billing-popup .sub-total h3 {
  width: 60%;
  color: #0048ba;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}
.billing-popup .sub-total h5 {
  width: 40%;
  color: #0048ba;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}
.billing-popup .bottom-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: initial;
}

.billing-popup .bottom-content {
  margin-top: 18px;
}

.billing-popup .bottom-content p a {
  color: #0048ba;
  text-decoration: underline;
  font-weight: 600;
}
.billing-popup .bottom-content p span {
  font-style: italic;
}

.billing-popup .time-keeper-popup.duration-popup {
  padding: 0px 12px 25px !important;
  min-height: initial !important;
}
.billing-content ul li p {
  font-size: 14px;
  line-height: initial;
  font-weight: 600;
  overflow: auto !important;
}

.billing-content ul li p span {
  font-weight: 500;
}
.edit-my-details-content.billing-content .dropdown-toggle::after {
  display: none;
}
ul.subscription-selection span:nth-child(2) {
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-top: -5px;
}
ul.subscription-selection li {
  display: flex;
  justify-content: space-between;
}
ul.subscription-selection li {
  line-height: initial;
  padding-bottom: 5px;
}
ul.subscription-selection {
  padding-bottom: 5px;
}
.billing-content ul:nth-child(5) {
  border-bottom: 1px solid #b0b0b0;
}
.billing-popup .billing-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 30px;
}

.billing-popup .billing-btn button {
  width: 80% !important;
  max-width: 215px;
}
.change-billing-popup h6 {
  font-size: 17px;
  color: #105ace;
}

.change-billing-popup .card-details ul li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.change-billing-popup .remove-card-btn {
  display: flex;
  justify-content: flex-end;
}

.change-billing-popup .remove-card-btn span {
  color: red;
  width: 40%;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.billing-popup .billing-details-section h3.section-title {
  font-size: 17px;
}
.subscription-selection-popup .sub-total {
  padding: 0;
}
.subscription-selection-popup .billing-details-section {
  padding-bottom: 20px;
}
.subscription-selection-popup .billing-details-section {
  padding-bottom: 20px;
}

.subscription-selection-popup .total-cost h5 {
  font-size: 16px;
  margin: 5px 0px 20px;
}
.subscription-selection-popup .form-group span {
  font-size: 13px;
  font-style: italic;
}
.billing-popup .time-keeper-popup.duration-popup {
  background: #f6f7fb;
}
.billing-popup .target-heading {
  color: black;
  padding: 14px 12px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.billing-popup .target-heading h6 {
  font-size: 18px;
  margin: 0;
  line-height: 14px;
  color: #333 !important;
}

.newContent_popup .select-box select.form-control,
.newContent_popup .staff-form input.form-control {
  font-size: 12px;
  color: #333 !important;
  margin-right: 8px;
  font-weight: 400;
}

.newContent_popup .inner-content {
  padding: 10px;
}

.newContent_popup .cross-popup {
  margin: 0 !important;
}

.newContent_popup .btn-edit {
  color: white !important;
}
.newContent_popup .back-popup {
  text-align: right;
  margin: 20px 0px;
  font-size: 25px;
  color: #929292;
}
.newContent_popup::-webkit-scrollbar {
  width: 0px;
}

.newContent_popup::-webkit-scrollbar-track {
  background: #848484;
  border-radius: 10px;
}

.newContent_popup::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: #b4b4b4;
  border-radius: 10px;
}

@media screen and (max-width: 610px) {
  .newContent_popup {
    max-width: 322px;
    width: 100%;
    height: 550px;
  }
}
