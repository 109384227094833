.content-container.calculator-container {
  padding: 40px 0;
  /* min-height: calc(100vh - 80px); */
}
.bg-lightblue {
  background-color: #e0e8f5 !important;
}
/* .title h3:before {
  content: "";
  position: absolute;
  width: 200px;
  height: 3px;
  background: #f8d400;
  top: 100%;
  left: 0;
} */

.title h3 {
  font-size: 30px;
  margin-bottom: 0;
  padding-bottom: 6px;
  color: #000;
  position: relative;
  font-family: sans-serif;
}
.form-container {
  padding: 40px 0;
}

.pool-site-input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.pool-site-input .form-control {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #a6a6a6;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  border-radius: 5px;
}
.form-group {
  margin-bottom: 5px;
}
.pool-site-input span {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
}
.form-control::placeholder {
  color: #000;
}
.liters.form-label {
  height: 40px;
  text-align: center;
  margin: 5px 0 20px;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 8px 30px 8px 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  background: none;
  background-color: #d9d9d9;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  position: relative;
}

.col i.fas.fa-sort-down {
  position: absolute;
  cursor: pointer;
  z-index: 9;
  right: 12px;
  top: 8px;
  background: #fff;
  width: 20px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 23px;
}
label.form-label {
  color: #28b8f2;
}
label.liters.form-label {
  color: #fff;
  max-width: 41%;
  width: 100%;
  float: right;
}
.custom-btn .btn {
  width: 100%;
  text-transform: capitalize;
  background-color: #008bbc;
  border-color: #008bbc;
  color: #fff;
  height: 40px;
  line-height: 15px;
}
label.form-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px 8px 8px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  height: 40px;
  margin-bottom: 0;
}
label.target.form-label {
  justify-content: center;
  text-align: center;
  border: 1px solid #28b8f2;
  padding: 8px 12px;
}
label.target.full-width.form-label {
  justify-content: left !important;
  text-align: left !important;
  padding: 8px 12px;
}
input.form-control {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  border-radius: 5px;
}
.pool-depth .form-group {
  margin: 5px 0 0;
}
.spec-depth.form-control {
  border: 1px solid #a6a6a6;
}
.spec-depth.redBorder.form-control,
.pool-site-input .redBorder.form-control {
  border: 2px solid red;
  margin: 0px 0 0;
}
.spec-depth.redBorder.form-control::placeholder,
.pool-site-input .redBorder.form-control::placeholder,
.redBorder.form-control::placeholder {
  font-size: 10px;
  color: red;
}
.cost {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  text-align: left;
  max-width: 50px;
  margin: 0 auto;
}
/* .calculate-btn .btn {
  background: #f8d400;
  color: #000;
  border-radius: 50px;
  border-color: transparent;
} */
.btn-show .back-btn .btn {
  background: #fdfd64;
  border-radius: 50px;
  border-color: #fdfd64;
  color: #000;
  min-width: 100px;
}
.showdata {
  background: #f9d30d;
  color: #000;
  margin: 5px 0 5px 5px;
  /* max-width: calc(100% - 14.7%); */
  width: 100%;
  padding: 2px 12px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  font-style: italic;
  display: flex;
  max-width: calc(100% - (14.44% + 5px));
}
.showdata:last-child {
  width: 100%;
  display: flex;
  align-items: center;
}
.show-result {
  width: 100%;
  display: flex;
  align-items: center;
}
.total-result {
  width: 86%;
  text-align: right;
}

.total-result-bottom {
  width: 86%;
}
.total-amount {
  width: calc(100% - 86%);
  text-align: left;
  max-width: 60px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.total-result h4 {
  text-align: right;
  margin-bottom: 0;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  padding-right: 5%;
}
.hide {
  display: none;
}
.form-row .show {
  width: 100%;
  display: block;
}
.btn-hide {
  padding-top: 15px;
  width: 100%;
  display: none;
}
.btn-show {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}
.btn-show .btn {
  border-color: transparent;
  font-family: sans-serif;
}
/* .calculate-btn {
  width: 100%;
  max-width: 270px;
} */
.btn-show .calculate-btn {
  margin-left: 10px;
  text-align: center;
}
.btn-hide .calculate-btn {
  max-width: 300px;
  margin-left: auto;
}
/* .save-btn {
  max-width: 200px;
  margin-left: 10px;
} */
/* .back-btn {
  max-width: 100px;
  white-space: nowrap;
} */
.back-btn .btn {
  background-color: transparent;
  /* border: none; */
  transition: 0.3s all ease-in;
  min-width: 100%;
  width: 100%;
}
.back-btn .btn span {
  padding-right: 5px;
  font-size: 14px;
  line-height: 16px;
}
.save-btn .btn,
.save-btn .btn:focus,
.save-btn .btn:hover {
  width: 100%;
  background: #007300;
  border-color: #007300;
  color: #fff;
  border-radius: 50px;
}
.content-container.cs-container {
  min-height: calc(100vh - 80px);
}
.cs-box {
  margin-bottom: 50px;
}
.cs-box:last-child {
  margin-bottom: 0;
}
.cs-heading {
  width: 100%;
  text-align: center;
  color: #006600;
  margin-bottom: 30px;
}
.pool-size.table {
  border: 2px solid #000;
}
.pool-size.table td {
  background: #f8d400;
  border: none;
  padding: 5px 4px;
  color: #000;
  font-weight: 600;
}
.pool-size.table td span {
  padding: 0 5px;
}
.total-cost.table td {
  border: none;
}
.total-cost.table {
  margin: 15px 0;
}
.total-cost.table tbody tr:last-child td {
  font-weight: 600;
  font-size: 20px;
}
.pool-size.table td:first-child,
.total-cost.table td:first-child {
  text-align: right;
}
.total-cost.table td:last-child {
  font-weight: 600;
  text-align: right;
}
.total-cost.table td:nth-child(2n) {
  text-align: left;
  padding: 12px 6px;
}
.total-cost.table tr:nth-child(2n) span {
  font-weight: 700;
  color: #c2c6d2;
  font-size: 15px;
}

.quantity-table .table thead th {
  background: #333;
  color: #fff;
  vertical-align: top;
}
.quantity-table .table-bordered td,
.quantity-table .table-bordered th {
  padding: 5px 10px;
}
.quantity-table .table-bordered td:nth-last-child(2) span {
  font-weight: 700;
}
.quantity-table .table-bordered tr td:first-child {
  margin-bottom: 0;
  border-right: none !important;
}
.quantity-table .table-bordered td.value {
  border: none;
  text-align: left;
  width: 17%;
  padding-left: 20px;
  font-weight: 700;
  color: #28c1f7;
  white-space: nowrap;
}
.quantity-table .table-bordered td:first-child {
  text-align: left;
  background: #f8d400;
  color: #000;
}
.table-bordered td,
.table-bordered th {
  border: none;
}
.quantity-table .table-bordered {
  border: none;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  white-space: pre-wrap;
  border-spacing: 0;
}
.quantity-table table tr td {
  border: 1px solid #908c8c !important;
  border-collapse: collapse;
}

.quantity-table table tr td {
  border-bottom: none !important;
}

.quantity-table table tr td:first-child {
  border-top: none;
}

.quantity-table table tr td:last-child {
  border-bottom: 1px solid #908c8c !important;
}

.quantity-table table {
  border-bottom: 1px solid #908c8c !important;
}

.quantity-table table thead tr th {
  border: 1px solid #908c8c !important;
}

.table thead th {
  border-bottom: none;
}
.quantity-table .table-bordered th:first-child {
  text-align: left;
}
.quantity-table .table-bordered td:first-child span {
  font-style: italic;
  padding-left: 30px;
  width: calc(100% - 80px);
  word-spacing: 5px;
  white-space: pre-wrap;
}
.quantity-table .table-bordered th:first-child span {
  padding-left: 35px;
  font-size: 14px;
  color: rgb(225, 225, 225, 0.7);
}
.quantity-table .table-bordered td .buy-cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quantity-table .table-bordered td:first-child .quantity {
  font-style: italic;
}
.quantity-table .table tbody tr:last-child td {
  max-width: 100%;
  font-weight: 700;
  color: rgb(0, 0, 0, 0.3);
}
.quantity-table .table-bordered td:first-child .sub-total {
  text-align: right;
  min-width: 100%;
}

.cost-result {
  text-align: right;
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  font-weight: 700;
  max-width: 55px;
  margin: 0 auto;
}
.any {
  width: 100%;
  display: flex;
  margin-top: 5px;
}
.cost-of-salt {
  width: 100%;
  max-width: 72%;
}
.cost-of-input {
  width: 14%;
  padding-left: 10px;
}
.cost-of-input .form-control {
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ml-auto .calculate-btn {
  min-width: 179px;
  margin-left: auto;
  padding-top: 25px;
  text-align: right;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.hideOptions {
  visibility: hidden;
  position: absolute;
}

.limit {
  border: 3px solid red !important;
  color: yellow !important;
}
.calculate-btn .btn {
  float: right;
  padding: 8px 12px;
  color: white;
  font-family: "Exo 2" !important;
  border-radius: 52px;
  font-weight: 700 !important;
  font-size: 14px;
}
.calculate-btn .withoutdrainage,
.calculate-btn .withoutdrainage:hover,
.calculate-btn
  .withoutdrainage:focus
  .calculate-btn.draining-btn
  .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #008bbc !important;
  color: white;
  border: 1px solid #008bbc !important;
}
.calculator-main .admin-content {
  padding: 0px 0px !important;
}

.calculator-container .form-control:disabled,
.calculator-container .form-control[readonly],
.calculator-container .form-control:disabled::placeholder {
  color: #5d5d5d !important;
  -webkit-text-fill-color: #5d5d5d;
  opacity: 1;
}
.btn-show .back-btn .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calculator-main body {
  background-color: #e0e8f5;
}
.calculator-container .custom-btn .btn {
  width: 100%;
  text-transform: capitalize;
  background-color: transparent !important;
  border: none !important;
  color: #333;
  text-align: left !important;
  /* height: auto; */
  padding: 0px 2px;
  line-height: 0px;
  font-weight: 600;
  font-size: 12px;
  margin-top: -5px;
}
.ideal-reading.form-group {
  margin-bottom: -10px !important;
}
.ideal-reading.form-group .custom-btn {
  line-height: 8px;
}
.change-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.change-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid #a7acb1;
  background-color: white;
  border-radius: 5px;
  padding: 0px 5px;
  font-size: 12px;
  flex-wrap: wrap;
  font-weight: 600;
  color: #8d8d8d;
  min-height: 28px;
}
.change-layout span {
  padding: 0 4px !important;
  font-size: 14px !important;
}
.admin-content-view .calculator-container .form-control,
.admin-content-view .calculator-container .form-control::placeholder {
  color: #5d5d5d;
  -webkit-text-fill-color: #5d5d5d;
  opacity: 1;
}
.calculator-container .form-control {
  color: #5d5d5d !important;
  -webkit-text-fill-color: #5d5d5d;
  opacity: 1;
}

.calculator-container input.form-control::placeholder {
  color: #5d5d5d !important;
  -webkit-text-fill-color: #5d5d5d;
  opacity: 1;
}
.calculator-container .form-control:disabled,
.calculator-container .form-control[readonly] {
  background-color: #edf0f4;
  opacity: 1;
}
.calculator-container .btn-show button {
  font-family: "Exo 2" !important;
  font-weight: 600;
  min-width: 100px;
}
.manage-pool.manage-pool-setup.form-group {
  margin-top: 0px;
}
.calculator-main .admin-container {
  background: #e0e8f5;
}
.cs-container .total-cost input {
  min-width: 65px;
  width: inherit;
  border-radius: 5px;
  border: 1px solid #cac0c0;
  text-align: right;
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 5px;
  background-color: #f4f4f5;
}
.total-cost td.calc-pool-cost {
  padding-right: 6px !important;
}
.pool-depth input::placeholder {
  font-size: 12px !important;
  padding: 0px !important;
  font-weight: 600 !important;
}
.salt-msg {
  max-width: calc(100% - 22.6%);
  font-size: 10px;
  line-height: 11px;
  color: #d71414;
  padding-left: 7px;
  display: flex;
  margin-bottom: 5px;
  font-style: italic;
}
.salt-msg span {
  font-size: 14px;
  font-weight: 900;
  margin-right: 3px;
}

/* 
confirm chemial popup */
.added-chemicals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d4d4d4;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.added-chemicals .open-date.client-notes {
  width: auto;
}

.added-chemicals h6 {
  font-weight: 500;
  margin-bottom: 0px;
}
.added-chemicals:last-child {
  margin-bottom: 0px;
}

.pool-color.choose-color {
  display: block;
  width: 100%;
  background-color: white;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 5px;
}
.pool-color.choose-color {
  margin-top: 3px;
}
.pool-color.choose-color h6 {
  width: 82%;
  margin-bottom: 0px;
  text-align: left;
  font-size: 12px;
  padding: 4px 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pool-color.choose-color .client-notes {
  display: flex;
  justify-content: center;
  width: auto;
  align-items: baseline;
  margin-bottom: 0px;
}
.pool-color.choose-color .client-notes .basic input {
  height: 18px;
  width: 18px;
  border: 1px solid #d4afaf;
  left: 2px !important;
  margin-top: 0px;
}
/* .pool-color.choose-color   {
  height: 18px;
  width: 18px;
  padding: 9px;
} */
.select-color {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cfcaca;
  margin-bottom: 3px;
  border-radius: 5px;
}
.pool-color.choose-color {
  margin-top: 0px;
}

.pool-color.choose-color
  .select-color
  .client-notes
  .basic
  input:checked:after {
  top: -1px !important;
}

.pool-color.choose-color .client-notes .basic input:checked:after {
  padding: 9px;
}

/* .pool-color.choose-color  .client-notes .basic .form-check {
  margin-left: 0px;
  line-height: 0;
  margin-top: -4px;
} */
.pool-color.choose-color .client-notes .basic .form-check {
  margin-left: 0px;
  line-height: 0;
  margin-top: 0;
  min-height: fit-content;
  margin-bottom: initial;
  height: 18px;
  width: 18px;
}
.pool-color.choose-color .open-date.client-notes {
  width: 18%;
}
.first-visit {
  margin-bottom: 30px;
}
.first-visit {
  margin-bottom: 30px;
}

.green-pool-working .appointment-total {
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 15px;
}

.green-pool-working .appointment-total p {
  margin-bottom: 0px;
  line-height: initial;
  color: #69bef1;
  font-size: 12px;
}

.green-pool-working .appointment-total p:first-child {
  color: #0a23f2;
  font-weight: 600;
}
.multi-visit-cost h5:first-child {
  color: #0a23f2;
  font-weight: 600;
}
.multi-visit-cost h5 {
  color: #69bef1;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}
.green-pool-working .appointment-view .first-visit h3 {
  color: #0a23f2;
  font-size: 15px;
}
.green-pool-treatment .footer-btn.view-btn.footer-dainage-btn button {
  width: 31%;
  font-size: 11px !important;
  padding: 0px !important;
}
.green-pool-working .appointment-view .first-visit h3 span {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  padding-left: 5px;
}
.green-pool-color.form-group {
  margin-top: 8px;
}

.footer-btn.view-btn.footer-dainage-btn .withoutdrainage {
  background-color: #008bbc;
  color: #fff;
}
.finalize-cost-page .footer-btn.view-btn.footer-dainage-btn button {
  width: 35% !important;
  border-radius: 50px !important;
  font-size: 12px !important;
}

.footer-btn.view-btn.footer-dainage-btn .withoutdrainage {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.footer-btn.view-btn.footer-dainage-btn .withoutdrainage span {
  padding-right: 5px;
}
.footer-btn.view-btn.footer-dainage-btn button:first-child {
  background: #fdfd64;
  border-radius: 50px !important;
  border-color: #fdfd64 !important;
  color: #000;
  font-size: 12px !important;
  padding: 5px 12px !important;
  min-width: 70px;
  width: fit-content !important;
}

.finalize-cost-page .footer-btn.view-btn.footer-dainage-btn .withoutdrainage {
  width: 38% !important;
  padding: 5px 10px !important;
}
.retest-heading {
  font-style: italic;
  font-size: 12px;
  color: #666464;
}
.start-heading {
  font-size: 14px;
  margin-right: 5px;
}

.footer-btn.view-btn.footer-treatment button {
  width: 32%;
  padding: 0px !important;
  font-size: 12px !important;
}
.footer-btn.view-btn.footer-treatment button:nth-child(2) {
  border: 1px solid #1aa01d !important;
  color: #2fac41 !important;
}
h3.chemical-require {
  margin-bottom: 0px;
  color: #333 !important;
  font-size: 14px !important;
}
.green-treatment-heading {
  color: #0a23f2;
}

.cancel-chemical {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appointment-container.finalize-cost-page.green-pool-working {
  padding-top: 30px;
}
.green-pool-cscontainer {
  margin-top: 50px;
}
.finalize-cost-page .footer-btn.view-btn.footer-dainage-btn .withoutdrainage a {
  color: white;
}
.footer-btn.view-btn.footer-treatment.footer-dainage-btn button:nth-child(2) {
  border: 1px solid #008bbc !important;
  color: #ffffff !important;
}
.drain-text {
  font-size: 14px;
  font-style: italic;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 5px;
  color: #28b8f2;
  text-align: center;
  line-height: initial;
  font-weight: 600;
  margin: 15px 0px;
}
.sorting-services span.cancel-icon i {
  margin-right: 8px;
  color: red;
  font-size: 18px;
}
.first-visit h3.chemical-require {
  margin-bottom: 3px;
}

.cancel-chemical span.plus-icon i {
  color: red;
  font-size: 18px;
}
.green-pool-working .client-pro-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.green-pool-working .first-visit .client-profile {
  width: 100%;
}
.calculator-container .duration-color {
  color: #69bef1 !important;
}
.media24 {
  background-color: #e7eff9;
}
.media25 {
  background-color: #d1e0f3;
}
.media27 {
  background-color: #a4c4ea;
}
.media28 {
  background-color: #72a2dc;
  color: #d8d5d5;
}
.media30 {
  background-color: #3d7fcf;
  color: #d8d5d5;
}
.media32 {
  background-color: #2d2dff;
  color: #d8d5d5;
}
.media36 {
  background-color: #0000b8;
  color: #d8d5d5;
}
.pool-report .pool-color.choose-color {
  display: block;
  width: 100%;
  background-color: white;
  padding: 5px 5px 2px;
  padding-bottom: 2px;
  border-radius: 5px;
}
/* .pool-report .select-color {
  margin-bottom: 8px;
} */
.pool-report .calc-btn-bottom {
  margin-top: 145px;
}
/* .pool-report .green-pool-color.form-group {
  margin-top: 40px !important;
} */
.sand-filter-replacement {
  padding-top: 0px !important;
}
.sand-filter-replacement .footer-btn.view-btn {
  margin-top: 50px !important;
}
.sand-filter-replacement .first-visit {
  margin-bottom: 10px;
}
.confirm-chemical-popup .view-btn {
  margin-top: 35px;
}
.calc-btn-reset .back-btn .btn {
  background: #fdfd64;
  border-radius: 50px;
  border-color: #fdfd64;
  color: #000;
  padding: 7px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calc-btn-reset {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.calc-btn-reset .back-btn {
  padding-top: 10px;
}
.chemical-popup .time-keeper-popup.duration-popup {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 999999999;
  max-width: 350px;
  width: 100%;
  margin: 10px auto;
}
.chemical-popup .time-keeper-popup.duration-popup h6 {
  text-align: center;
  margin-bottom: 35px;
}
.chemical-popup .time-keeper-popup.duration-popup {
  padding: 28px 25px;
}
.chemical-popup .time-keeper-btn {
  margin-bottom: 0px;
}
.chemical-popup
  .time-keeper-btn
  .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #1aa01d !important;
  color: white !important;
}
.multi-visit-cost h5:nth-child(2) {
  color: #0a23f2 !important;
}
.actual-size {
  max-width: 100% !important;
  padding: 2px 9px;
}
.testing-method {
  background-color: white;
  padding: 15px 12px;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 10px;
}

.testing-method p span {
  margin-right: 5px;
}

.form-group.tempp-input.form-group label {
  color: #6c757d !important;
}

.content-container.calculator-container .time-to-heat input,
.content-container.calculator-container .time-to-heat input::placeholder {
  background: #28b8f2 !important;
  color: #fff !important;
  font-weight: 700 !important;
  border: 1px solid #28b8f2;
  -webkit-text-fill-color: #fff !important;
}

.testing-method p {
  font-size: 12px;
  line-height: initial;
  font-style: italic;
  color: #24befc;
  margin-bottom: 6px;
  display: flex;
}

.testing-method h6 {
  color: #28b8f2;
  font-weight: 700;
}
.testing-method p.alert-text {
  color: red;
  margin-bottom: 10px !important;
}
.testing-method p.alert-text span {
  font-size: 16px;
  font-weight: 900;
  font-weight: 600;
}

/* .form-container.waterVolume .form-control {
  color: #333 !important;
  background-color: #d9d9d9 !important;
  border: 2px solid #c7c7c7 !important;
  font-weight: 700 !important;
  -webkit-text-fill-color: #333;
  pointer-events: none;
} */
.form-container.waterVolume .form-control {
  color: #333 !important;
  font-weight: 600 !important;
  -webkit-text-fill-color: #333;
  pointer-events: none;
}
.form-container.waterVolume .form-control:-webkit-autofill,
.form-container.waterVolume .form-control:-webkit-autofill:hover,
.form-container.waterVolume .form-control:-webkit-autofill:focus,
.form-container.waterVolume .form-control:-webkit-autofill:active {
  background-color: white !important;
}
.form-container.waterVolume .custom-select:disabled {
  color: #333 !important;
  background-color: #d9d9d9;
  opacity: 1 !important;
  border: 2px solid #c7c7c7;
  font-weight: 600;
}
/* .form-container.waterVolume .calc-btn-bottom.form-row {
  margin-top: 308px;
} */
.custom-input span.cholinator-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
  padding-left: 10px;
}
.custom-input span {
  text-align: center;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #535252;
  font-weight: 700;
  pointer-events: none;
  margin-top: -1px;
}
.custom-input .mm {
  font-weight: 500;
  font-size: 12px;
  font-style: italic;
}
.custom-input {
  position: relative;
}
.custom-input.form-group input {
  padding-left: 20px;
}
.waterVolume select {
  padding-right: 15px;
}
label.bags-label.form-label {
  line-height: initial;
}
.error-text {
  border: 2px solid red !important;
}
.error-text::placeholder {
  color: red !important;
}
.drain-percentage input {
  border: 1px solid #7f7f7f !important;
  background: #7f7f7f !important;
  color: white !important;
  -webkit-text-fill-color: #fefefe;
  padding-left: 8px !important;
}

.drain-percentage input::placeholder {
  color: white !important;
}
.drain-percentage span {
  color: white !important;
}
.admin-content-view .drain-percentage .form-control,
.admin-content-view .drain-percentage .form-control::placeholder {
  color: white !important;
  -webkit-text-fill-color: #fefefe;
}
.source-popup .form-row {
  display: flex;
  justify-content: flex-start;
}
.source-popup .custom-select-time {
  padding-left: 0px;
  margin-bottom: 0px;
}
.source-popup .custom-select-time label {
  background: #e0e8f5;
}
.source-popup .time-keeper-popup {
  padding-top: 20px !important;
}
.bg-color-source {
  background: #e0e8f5;
  padding: 5px;
  border-radius: 5px;
}
.source-popup .footer-btn.view-btn {
  margin-top: 30px;
}
.update-source-popup .pending-water-test p {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
}

.update-source-popup::before {
  z-index: 999 !important;
}

.update-source-popup .time-keeper-popup.duration-popup.event-popup {
  z-index: 999 !important;
}
.drain-btn button span {
  font-style: italic;
  font-size: 12px !important;
}
.time-keeper-popup .staff-close {
  background-color: transparent;
  padding-top: 15px;
  margin-top: -14px;
}
.footer-btn.view-btn.footer-dainage-btn .dropdown button {
  width: 100% !important;
  padding: 0;
  background-color: transparent !important;
  border: 1px solid #1aa01d !important;
  border-color: #1aa01d !important;
  color: #1aa01d !important;
  border-radius: 35px !important;
}
.footer-btn.view-btn.footer-treatment.footer-dainage-btn.green-drin-btn
  .client-option.service-edit-icon {
  width: 40% !important;
}
.custom-input.drain-percentage.form-group input {
  background-color: #28b8f2 !important;
  border: 1px solid #28b8f2 !important;
}
p.readings-text {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  color: red;
  font-style: italic;
  margin-top: 5px;
  font-weight: 500;
}

p.readings-text span:first-child {
  margin-right: 5px;
  font-size: 18px;
  margin-top: 0px;
}

p.readings-text span.link-tag {
  color: blue;
  font-size: 12px !important;
  margin-right: 0px !important;
  cursor: pointer;
  border: 1px solid #28b8f2 !important;
}
.per-day .mm {
  font-style: italic;
  font-size: 12px;
}
/* .wasted-water-btn {
  margin-top: 100%;
} */

span.loss-text {
  font-weight: 500;
  font-size: 12px;
  justify-content: flex-start;
  padding-left: 12px;
  font-style: italic;
}

/* .waterPoolStatus {
  color: #333 !important;
  background-color: #d9d9d9 !important;
  opacity: 1 !important;
  border: 2px solid #c7c7c7 !important;
  font-weight: 600;
} */
.waterPoolBack {
  margin-top: 70%;
}
.check-waterVolume {
  pointer-events: none !important;
}
.calc-cancel i {
  position: absolute;
  right: 22px;
  top: 1px;
  font-size: 22px;
  cursor: pointer;
  opacity: 0.5;
}

.calc-cancel {
  position: relative;
  margin: 10px 0px;
}

.drainBg {
  color: white;
  background: #f10808fa;
}
.back-option.calc-option {
  margin-top: 10px;
  margin-bottom: 0px;
}

.zodiacCalcium {
  margin-top: 0;
  padding-top: 0;
}
/* .zodiacCalcium p {
  font-style: normal;
} */

/* .blackSpotFont .select-color h6{
  font-size: 8px;
} */
/* .blackSpotFont .select-color h6 {
  font-size: 11px;
  width: 85%;
  padding-right: 0px !important;
} */
.blackSpotFont .open-date.client-notes {
  width: 15% !important;
}
.select-color .client-notes .basic input:checked:after {
  top: -2px !important;
}
.new-summary-page.appointment-container.finalize-cost-page.green-pool-working {
  padding-top: 0px;
}
.upload-img-calc {
  position: absolute;
  right: 17px;
  top: 0px;
  /* z-index: 99; */
}

.upload-img-calc input {
  height: 0px;
  width: 0px;
  visibility: hidden;
  display: none;
}
.calendar-cross .title .back-option.calc-option {
  position: absolute;
  right: 5px;
  top: 2px;
  margin-top: 0;
  margin-bottom: 0px;
}
.upload-img-calc i {
  font-size: 20px;
  cursor: pointer;
}
/* .upload-img-section{
  margin-top: 20px !important;
} */
.calendar-cross .upload-img-calc {
  position: absolute;
  right: 56px;
  top: 0px;
  z-index: 99;
}
.upload-img-section .upload-img-calc label.form-label {
  width: auto;
  right: 0px;
  justify-content: flex-end;
  margin-left: auto !important;
}
.processing:before {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  background: #0000004a;
  left: 0px;
  right: 0px;
  top: 0pc;
  bottom: 0px;
}
.processing .process-image {
  z-index: 99999;
  color: black;
  position: fixed;
  background: white;
  padding: 20px;
  width: 80%;
  left: 0px;
  transform: translate(-50%, -50%);
  width: 80%;
  left: 50%;
  margin: 0px auto;
  min-height: 25vh;
  top: 50%;
  font-size: 22px;
  border-radius: 5px;
}

.calendar-cross .upload-img-calc {
  position: absolute;
  right: 2px;
  top: -5px;
  z-index: 99;
}

.calendar-cross .back-option i {
  width: 15px;
  margin-top: 0px;
  height: 15px;
}

.calendar-cross .title {
  position: relative;
}
.calendar-cross .back-option.calc-option {
  padding-bottom: 30px;
  margin-top: 5px;
  margin-left: -4px;
}
.content-container.bg-lightblue.calculator-container.calendar-cross
  label.form-label {
  margin-left: auto !important;
}
.calculator-container .custom-select {
  font-weight: 700;
}
.custom-select {
  line-height: 12px;
}
.finalize-cost-page .footer-btn.view-btn.footer-dainage-btn .update-appt-btn {
  width: 48% !important;
}
.calculator-container .calculate-btn.draining-btn.calc-draining-btn {
  min-width: fit-content;
}

.calculator-container .calculate-btn.draining-btn.calc-draining-btn button {
  padding: 7px 20px;
}
.calculator-container .draining-btn-main .calculate-btn {
  min-width: 120px;
}
.calculator-container .draining-btn-main .calculate-btn button {
  width: 100%;
  padding: 8px 12px;
}
.form-box {
  min-height: calc(489px - 25px);
}
.form-box-increase {
  min-height: calc(524px - 25px);
}
/* .calculator-container select {
  background: url("../../assets/home/sortdown.svg") no-repeat right #d9d9d9 !important;
  background-size: 15px !important;
  background-position-y: 4px !important;
  background-position-x: 98% !important;
} */
.delete-icon select {
  background: #d9d9d9 !important;
}
.calculator-container .custom-select {
  /* font-weight: 500 !important; */
  -webkit-appearance: none;
  appearance: none;
  padding: 0px 40px 0px 10px;
  /* color: #a5a5a5; */
  background: #e1e1e1 !important;
  /* z-index: 9; */
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #a6a6a6 !important;
  line-height: initial !important;
}

.coverClassForDisableWholePage {
  pointer-events: none;
}
p.show-message {
  font-size: 11px;
  line-height: initial;
  padding-left: 5px;
  color: red;
}

.chemical-inventory-popup .calc-inventroy-section h6 {
  font-style: italic;
  color: #00b0f0;
  margin: 0;
}

.chemical-inventory-popup .calc-inventroy-section p {
  font-style: italic;
  margin: 0;
  line-height: initial;
  color: #00b0f0;
  font-size: 14px;
}

.chemical-inventory-popup .calc-inventroy-section {
  margin-bottom: 20px;
}
.thankyou-popup h4 {
  color: #00b0f0;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.thankyou-popup .pending-water-test {
  margin-bottom: 18px;
}
.calcium-popup .remove-spacing-text {
  margin-bottom: 0px;
  margin-top: 20px;
}
.calcium-popup .appointment-view p.warning-text:nth-child(4) {
  margin-top: 8px !important;
}
.calcium-popup .appointment-view {
  padding: 0px 12px !important;
}
/* .chemical-inventory-popup .calc-inventroy-section p:last-child {
  margin-top: 3px;
} */
.edit-chem-popup .chemical-table {
  margin-bottom: 20px;
}
.inventroy-alert-msg * {
  color: red !important;
}
.chemical-inventory-popup .italic-text p {
  font-style: italic;
  font-size: 14px;
  line-height: initial;
  color: #28b8f2;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 500;
}
.save-btn .drain-btn:focus {
  background: #f8d400 !important;
  color: #fff !important;
}
@media (min-width: 576px) {
  .col-sm-10.sm-offset-1.desktop-view {
    flex: 0 0 auto;
    text-align: center;
    width: 100%;
  }
}
.view-btn .desktop-view .view-btn {
  width: 100% !important;
  max-width: 170px;
}

html.calculator-main {
  background: #e0e8f5;
}

.pool-size-inputs p {
  margin: 0;
  font-weight: 400;
}
h3.pool-size-inputs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

h3.pool-size-inputs p {
  margin-left: 7px;
  font-weight: 500;
  color: #8d8d8d;
  font-size: 12px;
}
h3.pool-size-inputs p span {
  font-family: initial !important;
  padding: 0 !important;
  font-weight: 600;
}
.tempp-input label.form-label {
  color: #333;
}

.tempp-input input {
  background: #e1e1e1 !important;
  color: #333 !important;
}
.tempp-desc.testing-method p {
  margin-bottom: 0 !important;
}
.remove-dropdown::after {
  display: none;
}
.remove-dropdown select {
  padding-right: 10px !important;
}

.new-result .cost {
  width: 100% !important;
  max-width: 100%;
  text-align: left;
  padding: 0;
}

.new-result {
  margin: 5px 0;
}
.swim-modal-green .time-keeper-popup {
  background: #3df33d;
}
.swim-modal-green .appointment-view {
  background: #3df33d;
}
.swim-modal-green .event-popup .pending-water-test,
.swim-modal-red .event-popup .pending-water-test {
  justify-content: center;
}
.swim-modal-green .event-popup .pending-water-test h3 {
  color: gray;
  text-align: center;
}
.swim-modal-green .event-popup-btn.footer-btn.view-btn {
  background: #3df33d;
  border-radius: 9px;
}
.swim-modal-red .event-popup-btn.footer-btn.view-btn {
  background: red;
  border-radius: 9px;
}
.swim-modal-green .event-popup-btn.footer-btn.view-btn button:first-child,
.swim-modal-red .event-popup-btn.footer-btn.view-btn button:first-child {
  background: #fff !important;
}
.swim-modal-green .event-popup .pending-water-test h3,
.swim-modal-red .event-popup .pending-water-test h3 {
  font-size: 16px;
  max-width: 273px;
  margin: auto;
}

.swim_para {
  text-align: center;
  font-style: italic;
  margin-top: 6px;
  line-height: 1.2 !important;
}

.line_space h3 {
  line-height: 1.2 !important ;
}
.line_space p {
  line-height: 1.2 !important ;
}
.para_spacing {
  /* line-height: 21px; */
  line-height: 1.2 !important ;
}
.swim-modal-green.thankyou-popup h4 {
  font-size: 40px;
  color: #007503;
  margin-bottom: 10px;
  font-weight: 700;
  /* line-height: 1.5 !important; */
}
.swim-modal-green .time-keeper-popup.duration-popup.event-popup,
.swim-modal-red .time-keeper-popup.duration-popup.event-popup {
  padding: 12px 12px 20px;
}

.swim-modal-red .time-keeper-popup {
  background: red;
}
.swim-modal-red .appointment-view {
  background: red;
}
.swim-modal-red.thankyou-popup h4 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
}
.swim-modal-red .event-popup .pending-water-test h3 {
  text-align: center;
}

.emil-me-button {
  border: 1px solid #747070 !important;
}
@media only screen and (min-width: 991px) {
  .title.desktop {
    margin-top: 30px;
    font-size: 18px;
  }
  .title.desktop .upload-img-calc i {
    margin-top: 30px;
  }
  .btn-show .back-btn .btn {
    min-width: 70px;
    width: 100%;
  }
  .footer-btn.view-btn.footer-treatment.footer-dainage-btn button:nth-child(2) {
    border: none;
    color: #ffffff !important;
    font-size: 16px !important;
    line-height: 1 !important;
  }
  .footer-btn.view-btn.footer-dainage-btn button:first-child {
    font-size: 16px !important;
  }
  .title.desktop h3 {
    font-size: 24px !important;
  }
  .showdata {
    font-size: 16px !important;
  }
  .form-container {
    padding: 0px;
  }
  label.form-label {
    font-size: 16px !important;
  }
  .desktop-view-btn .btn-show {
    padding-top: 20px;
  }
  .desktop-view-btn .btn-show .save-btn .btn,
  .desktop-view-btn .btn-show .back-btn .btn {
    padding: 8px 30px !important;
    font-size: 16px;
  }
  .desktop-view-btn .btn-show .save-btn .btn span {
    font-size: 16px !important;
  }
  .desktop-view-btn .btn-show .save-btn .btn:active,
  .desktop-view-btn
    .btn-show
    .save-btn
    .btn-primary:not(:disabled):not(.disabled):active {
    background: #007300;
  }
  .desktop-view-btn .btn-show .save-btn .btn:active,
  .desktop-view-btn
    .btn-show
    .back-btn
    .btn-primary:not(:disabled):not(.disabled):active {
    background: #fdfd64;
  }
  .form-box {
    min-height: auto;
  }
  .calendar-cross .upload-img-calc {
    z-index: initial;
  }
  .green-pool-working .appointment-view .first-visit h3 span {
    font-size: 16px;
  }
  .view-appointment-details
    .appointment-container
    .appointment-view
    .search-title
    h3 {
    font-size: 18px;
  }
  .view-appointment-details
    .appointment-view
    .search-title
    .client-profile.desktop-screen
    h3 {
    font-size: 16px;
    line-height: 1.2 !important;
  }

  .finalize-cost-page.green-pool-working .appointment-total p:first-child {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .title.desktop h3 {
    font-size: 16px !important;
  }
}

.safe-to-swim {
  color: #1aa01d !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  font-family: "Exo 2", sans-serif !important;
}

.dont-swim {
  color: red !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  font-family: "Exo 2", sans-serif !important;
}
