.table-title h3 {
  font-size: 25px;
  text-transform: uppercase;
  color: #4088c5;
  font-family: "Exo 2", sans-serif;
}
.measure-table.table-bordered td {
  border: 1px solid #4088c5;
  padding: 6px 12px;
  color: #00b0f2;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #00b0f2;
}
.measure-table.table-bordered td:first-child {
  border-top: none;
}
.measure-table.table-bordered {
  border-top: 1px solid #4088c5;
}
.table-bordered td span.check-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table-bordered td span.check-img {
  width: 20px;
  cursor: pointer;
}
.table-bordered tr:first-child td {
  margin-bottom: -1px;
}
.table-bordered td span.circle {
  height: 18px;
  width: 18px;
  background: transparent;
  border: 1px solid #00b0f2;
  border-radius: 50%;
  display: inline-block;
}

.head-table.table {
  text-align: center;
  border: 2px solid #0070c0;
  margin-bottom: 0;
}
.pool-set-up .head-table {
  margin-bottom: 20px;
}
.head-table.table thead th {
  border-bottom: 1px solid #0070c0;
  vertical-align: top;
  background-color: #0070c0;
  color: #fff;
  border-top: none;
}
.head-table.table tbody td:first-child {
  font-weight: 700;
}
.head-table.table thead th:first-child,
.head-table.table tbody td:first-child {
  text-align: left;
}
.head-table.table tbody td {
  background-color: transparent;
  color: #00bdf7;
  font-weight: 600;
}

.disableDropdown {
  background: #e9ecef;
}

.head-table.table-bordered td,
.head-table.table-bordered th {
  border: 1px solid #00b0f0;
  padding: 8px 12px;
}
.view-btn .btn-default {
  background: #66ff33;
  color: #ce9904;
  border-color: #66ff33;
}

td.target-level-input {
  padding: 0 !important;
  height: 100%;
  vertical-align: middle;
}

.target-level-input .form-group {
  margin-bottom: 0;
}

.pool-chemistry-box {
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px 5px;
  margin-top: 25px;
}

.left-pool-analysis {
  width: calc(30% - 10px);
}
.right-pool-analysis {
  width: 70%;
  display: flex;
  gap: 7px;
}
.target-level-input .form-control {
  border: none;
  float: right;
  background: transparent;
  height: 35px;
  border-radius: 0;
  color: #0077c0;
  box-shadow: none;
  padding: 8px 12px;
  font-weight: 600 !important;
}

.pool-set-up .target-heading {
  color: black;
  padding: 14px 12px;
  border-radius: 5px;
  background-color: #ffffff;
}
.pool-set-up .right-pool-analysis h6 {
  text-align: center;
}
.pool-set-up .right-pool-analysis .form-group .form-control {
  text-align: center;
}
.chemistry-target
  .right-pool-analysis.target-level-input
  form:first-child
  input,
.chemistry-target
  .right-pool-analysis.target-level-input
  form:nth-child(3)
  input,
.chemistry-target
  .right-pool-analysis.target-level-input
  form:nth-child(4)
  input {
  color: #0096ee !important;
  font-weight: 500 !important;
}
.pool-setup.mineral-pool.chemical-setup
  .right-pool-analysis.target-level-input
  form:first-child
  input {
  color: #0096ee !important;
  font-weight: 500 !important;
}
.left-pool-analysis h5 span {
  color: #706f6f;
  padding-left: 5px;
  font-weight: 400;
  font-style: italic;
}
.pool-analysis .right-pool-analysis .form-group h6 {
  margin-bottom: 0px;
  min-width: 64px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
  width: 100%;
}
.chemical-setup .right-pool-analysis form {
  width: 33.33%;
}
.pool-accordian .outer-accordion .card-header .btn.btn-link {
  padding: 0px !important;
}
.pool-accordian .mob-accordion.outer-accordion.accordion li.grid-list-item {
  padding-left: 0px !important;
}
.pool-accordian .inner-accordion ul.grid-list .grid-box h3 {
  font-size: 18px;
  font-weight: 600;
}
.pool-accordian .inner-accordion ul.grid-list .grid-box h5 {
  font-size: 18px;
  color: #7c7c7c;
  font-weight: 400;
  font-family: "Exo 2" !important;
}
.pool-accordian .inner-accordion ul.grid-list .action-btn {
  padding-left: 0px !important;
}
.pool-accordian .card-body {
  padding-bottom: 0px !important;
}
.service-mbl.pool-accordian .mob-accordion.outer-accordion.accordion {
  border: none !important;
}
.drainage-cost-section h5 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}
.drainage-cost-section h6 {
  margin-bottom: 0px;
}
.drainage-cost-section input {
  text-align: center;
  font-size: 16px;
}
.drainage-cost-section span.require-icon {
  color: red !important;
  font-weight: 900;
  font-size: 20px;
  margin-left: 5px;
}
.drainage-cost-section h6 {
  font-size: 16px;
  font-weight: 600;
}
.drainage-cost-section .bottom-text {
  display: flex;
  padding-left: 10px;
  margin-top: 25px;
}
.drainage-cost-section .bottom-text span.require-icon {
  padding-right: 5px;
  line-height: 1;
}
.drainage-cost-section .bottom-text p {
  color: #0096ee !important;
  font-style: italic;
  line-height: 15px;
}
.rate-symbol {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.drainage-cost-section form {
  width: 80%;
  margin-left: auto;
}
.miniral-pool {
  margin-top: 20px;
}
.miniral-popup .form-group {
  position: relative;
}

.miniral-popup .form-group span {
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 40px;
  transform: translate(0%, -50%);
  color: #535252;
  font-weight: 700;
  pointer-events: none;
}
.miniral-popup .form-group span.cholinator-text {
  display: flex;
  justify-content: flex-start !important;
  padding-left: 10px;
}
.pool-set-up .service-mbl {
  margin-top: 0px;
}
.pool-set-up .grid-box .action-btn .btn {
  margin-right: 10px;
}

.balance-water .select-options i {
  position: absolute;
  z-index: 9;
  background-color: white;
  right: 10px;
  top: 6px;
  align-items: flex-start;
  font-size: 15px;
  line-height: 12px;
  width: 16px;
  height: 16px;
  text-align: center;
}
.balance-water .select-options {
  position: relative;
}
.balance-water .balance-water-box h6 {
  color: #7c7c7c;
  font-weight: 400;
  font-family: "Exo 2" !important;
  margin-bottom: 0px;
}

.balance-water-box h5 {
  margin: 0px !important;
}

.pool-set-up .balance-water h5 {
  margin-bottom: 0px;
}
.free-chlorine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

.free-chlorine select.custom-select {
  width: 100%;
}

.free-chlorine .chlorine-right {
  width: 60%;
}
.free-chlorine-setup {
  margin: 15px 0px;
}
.free-chlorine-setup .row {
  padding: 5px 0px;
  align-items: center;
}
.free-chlorine-setup.inner-chlorine h6 {
  padding-left: 5px !important;
}
.free-chlorine-setup h5 {
  margin: 0px !important;
}
.pool-set-up .view-btn {
  margin-top: 30px;
}
.pool-set-up .dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 0px 0px;
  color: #000;
  white-space: initial;
}
.pool-set-up .dropdown .dropdown-item:active {
  background-color: transparent;
}

.chemistry-target
  .right-pool-analysis.target-level-input
  form
  .chemicalclass.form-group
  input {
  color: #4a4848 !important;
}
.miniral-popup .modal-body input {
  padding: 6px 20px !important;
}
.miniral-popup .modal-body input:focus,
.miniral-popup .modal-body .form-control:focus {
  box-shadow: none !important;
}
.pool-setup.mineral-pool.drainage-cost-section h6 {
  line-height: 14px;
}
.pool-setup.mineral-pool.drainage-cost-section input {
  font-weight: 400;
}

/*********************green pool calculation *****************/
.pricing-setup h6 {
  color: #20b1e4;
  font-weight: 600;
  margin-bottom: 4px;
}

.pricing-setup p {
  color: #6dd7ff;
  line-height: 18px;
  margin-bottom: 5px;
  font-style: italic;
}
.pricing-setup {
  background-color: #eff4ff;
  padding: 10px;
  border-radius: 5px;
}
.green-pool-calculation .main-title h6 {
  font-size: 24px;
}
.pool-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pool-chemistry-box.green-pool-calculation .pool-table {
  display: flex;
  justify-content: space-between;
  align-items: initial;
}
.green-pool-table h5 {
  color: #0077c0;
  font-size: 16px;
}

.green-pool-table {
  margin-top: 20px;
}
.pool-table p {
  font-size: 14px;
  font-style: italic;
  color: #0077c0;
}
.green-one {
  background-color: #33ff33;
  border: 1px solid #33ff33;
}
.black-one {
  background-color: #ececec;
  border: #ececec;
}
.pool-table-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.right-pool-table {
  width: 30%;
  display: flex;
  justify-content: space-around;
}
.left-pool-table {
  width: 65%;
  padding: 3px 5px;
}
.left-pool-table p {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.right-pool-table p {
  color: #0077c0;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}
.right-pool-table span {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}
.green-two {
  background-color: #00b800;
  border: 1px solid #00b800;
}
.green-three {
  background-color: #006c00;
  border: 1px solid #006c00;
}
.green-four {
  background-color: #002e00;
  border: 1px solid #002e00;
}
.black-two {
  background-color: #d7d7d7;
  border: 1px solid #d7d7d7;
}
.black-three {
  background-color: #b4b4b4;
  border: 1px solid #b4b4b4;
}
.black-four {
  background-color: #5a5a5a;
  border: 1px solid #5a5a5a;
}
.black-one p,
.blackCalci1 {
  color: #858687;
}
.black-two p,
.blackCalci2 {
  color: #91979a;
}
.black-three p,
.blackCalci3 {
  color: #5d5a5e;
}
.black-four p,
.blackCalci4 {
  color: #d8d8d8;
}
.green-pool-table .left-pool-table.black-one p:first-child {
  font-weight: 700;
}
.green-pool-table .left-pool-table.black-one p:last-child {
  font-style: italic;
}
.green-four p,
.green-three p {
  color: #b9b9b9;
}
.left-pool-table.second-dosage p:last-child {
  font-style: italic;
}

.left-pool-table.second-dosage p:first-child {
  font-weight: 700;
  font-style: unset;
}
.dosage-part .pool-table-content {
  margin-bottom: 0px;
}
.note-info span {
  font-weight: 700;
  font-size: 18px;
  padding-right: 4px;
}

.note-info {
  display: flex;
  font-size: 12px;
  line-height: 15px;
  color: red;
  font-weight: 500;
  padding: 15px 5px 5px;
}
.pool-table p {
  margin-bottom: 8px !important;
  font-size: 12px;
  font-weight: 600;
}
p.granular {
  line-height: 0px;
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  color: #0077c0;
}
.pool-color .green-one,
.pool-color .green-two,
.pool-color .green-three,
.pool-color .green-four {
  padding: 5px 6px;
  font-size: 10px;
  width: 25%;
  display: inline-grid;
}

.pool-color {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.pool-color .green-three,
.pool-color .green-four {
  color: #b9b9b9;
}
.pool-color h6 {
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.pool-color {
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;
  margin-top: 20px;
}
.pool-cost-table hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.green-pool-table.pool-cost-table .left-pool-table.second-dosage p:last-child {
  font-style: inherit;
  font-weight: 700;
}
.green-pool-table.pool-cost-table .left-pool-table {
  width: 35%;
}
.green-pool-table.pool-cost-table .right-pool-table {
  width: 70%;
}
.green-pool-table.pool-cost-table .right-pool-table p {
  width: 25%;
  color: black;
  font-weight: 600;
  text-align: center;
  line-height: initial;
}
.bottom-cost-table p {
  color: #0077c0 !important;
}
.bottom-cost-table-new p {
  color: #69bef1 !important;
  font-weight: 500 !important;
  font-style: italic !important;
}
.left-pool-table.second-dosage.bottom-cost-table-new,
.right-pool-table.bottom-cost-table-new {
  margin-top: -5px;
}
.pool-chemistry-box.green-pool-calculation {
  margin-top: 0px;
  margin-bottom: 25px;
}

.right-pool-table input {
  min-width: 50px !important;
}

.right-pool-table form {
  min-width: 50px;
  width: inherit;
}
.main-titles-mob {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.reload-btn {
  background-color: #5591c3 !important;
  border-radius: 50% !important;
  border: 1px solid #5591c3 !important;
  height: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reload-btn img {
  margin-top: 0px;
  margin-left: -2px;
}
.search-pool-size {
  background-color: white;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-pool-size input {
  height: 35px !important;
  padding-right: 65px !important;
}

.search-pool-size .form-group span {
  position: absolute;
  right: 15px;
  top: 6px;
  font-size: 13px;
  font-weight: 500;
}

.search-pool-size .form-group {
  margin-bottom: 0px;
  position: relative;
  width: 50%;
}
.search-pool-size h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-right: 10px;
}
.acid-wash .right-pool-table {
  justify-content: flex-start;
  align-items: center;
}
.acid-wash .right-pool-table p {
  min-width: 50px;
  width: inherit;
}
.acid-wash hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
.acid-wash .right-pool-table input {
  padding-top: 0px;
  padding-bottom: 0px;
  height: auto;
}
.acid-wash .left-pool-table.second-dosage p:last-child {
  font-style: normal;
}
.drain-wash-heading {
  padding: 5px 6px;
  font-size: 12px;
  width: 25%;
}
.drainage-wash-table .right-pool-table {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.drainage-wash-table .right-pool-table {
  width: 70% !important;
}
.drainage-wash-table .left-pool-table {
  width: 30% !important;
}
.pool-table-content .target-level-input .form-control {
  padding: 8px 0px;
  text-align: center;
}
.pool-table-content .right-pool-table {
  align-items: center;
  justify-content: center;
}
.pool-chemistry-box.green-pool-calculation .left-pool-table.green-one {
  width: 63%;
}

.pool-chemistry-box.green-pool-calculation
  .right-pool-table.target-level-input {
  width: 39%;
}
.right-pool-table.target-level-input.high-value {
  justify-content: space-around;
}
.left-pool-table.second-dosage p span {
  font-style: italic;
  padding-left: 5px;
  font-size: 10px;
}
.target-level-input .form-group span {
  position: absolute;
  margin-top: 4px;
  left: 5px;
}
.target-level-input .form-group {
  position: relative;
}
.green-pool-cal .right-pool-table.target-level-input .form-group span {
  position: absolute;
  margin-top: 0px !important;
  left: -5px !important;
}

.pool-set-up .view-btn .dropdown {
  width: 100%;
}
.pool-set-up .view-btn .client-option.service-edit-icon {
  width: 49%;
}
.pool-set-up .view-btn .dropdown button {
  width: 100%;
  padding: 0px;
  background-color: transparent !important;
  border: 1px solid #939191 !important;
  color: #939191 !important;
}
.pool-set-up .view-btn .dropdown button::after {
  display: none;
}
.pool-set-up .view-btn .dropdown .dropdown-item {
  padding: 8px 12px !important;
}

/*****filter css *********************/
.green-pool-table.filter-table .pool-table p:nth-child(3) {
  width: 45% !important;
}
.green-pool-table.filter-table .pool-table p {
  width: 12% !important;
  line-height: initial;
  color: #000;
  text-align: center;
  font-style: inherit;
  font-weight: 700;
}
.filter-table .pool-table {
  align-items: center !important;
}
.filter-table .pool-table-content input {
  border: none !important;
  width: 100%;
}

.filter-table .pool-table-content .form-group {
  width: 100%;
  margin-bottom: 0px !important;
}

.filter-table .pool-table-content form {
  width: 100%;
}
.green-pool-table.filter-table .pool-table-content p:nth-child(3) form {
  width: 12%;
}
.filter-table .pool-table-content p {
  display: flex;
  align-items: center;
}
.green-pool-table.filter-table .pool-table-content p:nth-child(3) {
  width: 45% !important;
  line-height: initial;
}
.green-pool-table.filter-table .pool-table-content p {
  width: 12% !important;
  text-align: center;
  justify-content: center;
  margin-bottom: 0px;
}
.filter-table .pool-table-content input {
  color: #1daef7;
  text-align: left;
  padding: 0px !important;
  height: auto !important;
}
.green-pool-table.filter-table .pool-table-content p {
  color: #333;
  font-weight: 500;
}
.green-pool-table.filter-table .pool-table-content p span {
  font-style: italic;
}
.filter-table .pool-table-content input:focus {
  outline: none;
  box-shadow: none;
}
.filter-table .dosage-part {
  margin-bottom: 8px;
}
.dosage-part.filter-bottom-table .pool-table-content p {
  width: 60% !important;
  text-align: left !important;
  justify-content: flex-start;
}
.dosage-part.filter-bottom-table .pool-table-content p:last-child form {
  width: 50%;
}
.dosage-part.filter-bottom-table .pool-table-content p:last-child {
  text-align: right !important;
  justify-content: flex-end;
}
.dosage-part.filter-bottom-table .pool-table-content p:last-child span {
  min-width: 35%;
  text-align: left !important;
  padding-left: 10px;
}
.filter-table .filter-bottom-table .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dosage-part.filter-bottom-table .pool-table-content p:last-child form input {
  text-align: right !important;
}
/* .filter-bottom-table input.error-text.form-control {
  border: 2px solid red !important;
} */
.dosage-part.filter-bottom-table .pool-table-content .form-group span {
  width: auto !important;
}
.filter-glass-table .green-pool-table.filter-table .pool-table p {
  width: 15% !important;
}
.filter-glass-table .green-pool-table.filter-table .pool-table-content p {
  width: 20% !important;
}
.pool-table-content input.error-text.form-control {
  border: 2px solid red !important;
}
.filter-glass-table
  .green-pool-table.filter-table
  .glass-bottom-table
  p:first-child {
  width: 80% !important;
  text-align: right !important;
  justify-content: flex-end;
  font-style: italic;
}
.filter-glass-table .green-pool-table.filter-table .glass-bottom-table p {
  color: #36c4f4;
  font-style: italic;
}
.pool-table-content.glass-bottom-table {
  margin-top: -5px;
}
.filter-glass-table .green-pool-table.filter-table .pool-table p {
  font-weight: 600;
}
.green-pool-table.filter-table .pool-table-content p:nth-child(3),
.green-pool-table.filter-table .pool-table p:nth-child(3) {
  justify-content: flex-start !important;
  padding-left: 20px;
  text-align: left !important;
}
.green-pool-table.filter-table .pool-table p span {
  font-style: italic;
}
.pool-chemistry-box.green-pool-calculation.acid-wash.filter-glass-table
  p:nth-child(3) {
  justify-content: center !important;
  padding-left: 0px;
  text-align: center !important;
}
.pool-setup-second-visit .form-group button.btn.btn-primary {
  padding: 6px 10px;
  line-height: 1.2;
  color: #a5a5a5;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  font-size: 13px;
  height: 35px;
  font-weight: 400;
  border: 1px solid #ced4da;
}
.pool-setup-second-visit input {
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 5px;
  height: 35px;
  padding: 6px 10px 6px 10px;
  color: #a5a5a5;
  text-align: left;
}
.pool-setup-second-visit .col-6 {
  padding: 0px 10px;
}
.pool-color .black-one,
.pool-color .black-two,
.pool-color .black-three,
.pool-color .black-four {
  padding: 5px 2px;
  font-size: 10px;
  width: 25%;
  display: inline-grid;
}
.green-pool-table.filter-table .us-imperial-table .pool-table p {
  width: 30% !important;
}
.green-pool-table.filter-table .us-imperial-table .pool-table p:last-child {
  width: 35% !important;
}
.green-pool-table.filter-table .us-imperial-table .pool-table-content p {
  width: 30% !important;
}
.green-pool-table.filter-table
  .us-imperial-table
  .pool-table-content
  p:last-child {
  width: 35% !important;
}
.green-pool-table.filter-table
  .us-imperial-table
  .pool-table-content
  p:nth-child(3),
.green-pool-table.filter-table .us-imperial-table .pool-table p:nth-child(3) {
  justify-content: center !important;
  padding-left: 20px;
  text-align: center !important;
}
.green-pool-table.filter-table
  .us-imperial-table
  .pool-table-content
  p:nth-child(3)
  form {
  width: 30%;
}

.chemical-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}
.invoice-details-option .chemical-list {
  margin-bottom: 0px;
}
.chemical-list .open-date.client-notes {
  width: fit-content;
}

.chemical-display-section {
  background: white;
  border-radius: 8px;
  padding: 20px 15px;
  margin-top: 20px;
}
.chemical-list h6 {
  margin: 0;
}

.input-with-icons {
  min-width: 10px;
  /* background: red; */
  position: relative;
  height: 35px;
}

.input-with-icons span:first-child {
  left: 3px !important;
  position: absolute !important;
  margin-top: 4px !important;
  color: #0096ee !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.input-with-icons span:last-child {
  position: absolute;
  right: 0px;
  position: absolute;
  margin-top: 6px;
  color: #0096ee !important;
  font-weight: 500 !important;
  font-size: 12px;
  left: initial;
}

/* .poolsetup-input {
display: flex;
align-items: center;
justify-content: center;
}
.poolsetup-input input {
  max-width: calc(100% - 45px);
  margin-left: 5px !important;
  margin-right: 2px !important;
}
.poolsetup-input input:focus {
  border: 0 !important;
}
.poolsetup-input span,
.poolsetup-input span:first-child {
  position: relative !important;
  margin: 0 !important;
  line-height: 1;
} */

.right-pool-analysis.target-level-input form {
  width: 25%;
}
.tax-applicable-page form {
  width: 100%;
}

.tax-applicable-page .form-group {
  position: relative;
}

.tax-applicable-page .form-group span {
  position: absolute;
  right: 10px;
  top: 33px;
}
.tax-applicable-page .form-group input {
  text-align: left;
}
.tax-applicable-page .form-group label {
  color: #333 !important;
}
.pool-setup.mineral-pool.chemical-setup
  .right-pool-analysis.target-level-input
  form {
  width: 50%;
}
.mins-box {
  position: relative;
}

.mins-box.form-group span {
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 2px;
  color: #8d8d8d;
}
.mins-box.form-group input {
  padding-right: 30px !important;
}
.pool-setup.mineral-pool.chemical-setup
  .right-pool-analysis.target-level-input
  form {
  width: 50%;
}
.user-pool .pool-size-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-pool .pool-size-wrapper span {
  font-size: 16px;
  font-weight: 600;
}
.user-pool .pool-chemistry-box {
  padding: 0;
  margin: 0;
}
.user-pool .service-mbl .mob-table {
  padding: 10px 12px 15px;
  margin-top: 0px;
  border-radius: 0px !important;
}
.user-pool .service-title {
  border-radius: 0;
}
.user-pool .service-mbl .collapse {
  border-radius: 0;
}
.user-pool .service-mbl .accordion .card {
  margin-bottom: 15px;
  border-radius: 5px !important;
  border: none;
  /* padding: 0 12px; */
}
.user-pool .service-mbl .card-header {
  border-radius: 0px !important;
}
.user-pool .main-titles {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.user-pool .main-title-plus {
  color: #0077c0;
  padding: 4px 10px 0 0;
}
.user-pool-page .action-btn button {
  margin-right: 5px;
}
.user-pool-page ul.staff-list {
  padding: 0 !important;
}

.user-pool-page .mob-accordion.accordion .card-body {
  padding-left: 0px;
}
.user-pool-page .mob-accordion .card {
  padding: 0px 15px;
}
.pool-setup.mineral-pool.drainage-cost-section .row {
  align-items: center;
  margin-bottom: 10px;
}
.pool-setup.mineral-pool.drainage-cost-section .form-group {
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .left-pool-table.second-dosage p:first-child {
    font-weight: 700;
    font-size: 14px !important;
    font-style: unset;
  }
  .media-replacement.table-desktop-view
    .green-pool-table
    .main-table
    .pool-table
    p,
  .filter-glass-table.table-desktop-view
    .green-pool-table.filter-table
    .pool-table
    p {
    justify-content: center;
  }
  .filter-glass-table.table-desktop-view
    .green-pool-table.filter-table
    .pool-table-content
    p,
  .filter-glass-table.media-replacement-page.table-desktop-view
    .green-pool-table.filter-table
    .pool-table-content
    p {
    width: 15% !important;
  }
  .pool-chemistry-box.balance-water-box .free-chlorine-setup h5,
  .pool-setup.chemical-setup .left-pool-analysis h5,
  .pool-setup .chemistry-target .left-pool-analysis h5 {
    font-size: 16px;
    font-weight: 600;
  }
  .pool-chemistry-box.balance-water-box .free-chlorine-setup.inner-chlorine h5 {
    max-height: 50px;
  }
  .drainage-cost-section .target-heading h6,
  .pool-accordian.web-view-chlorinator
    .service-container
    .service-title
    h4
    button {
    font-size: 18px;
    font-weight: 600;
    /* text-transform: uppercase; */
    color: #3c424b;
  }
  .pool-accordian.pool-accordian.web-view-chlorinator
    .mob-accordion
    .heading-accordion
    button
    h5 {
    font-size: 16px;
  }
}
