.chemicals-outer {
    display: flex;
    justify-content: space-between;
}
.chemicals-left{
    width: 45%;
}
.chemicals-right {
    width: 53%;
}

.chemicals-right p {
    color: #000;
    font-weight: 400;
}

.chemicals-right ul {
    list-style-type: disc;
}
.chemicals-right ul li{
    font-style: italic;
    margin-bottom: 8px;
}
.accordion {
    width: 100%;
    margin: auto;
    background: none;
    border: none;
  }
  
  .accordion-item {
    border-bottom: 1px solid #c0c0c0 !important;
    background: none;
    border: none;
  }
  
  .accordion-header {
    width: 100%;
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    padding: 15px 10px;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    justify-content: space-between;
  }
  .accordion-header p{
    margin: 0px;
    font-size: 20px;
  }
  
  .accordion-header svg {
    height: 25px;
    width: 25px;
  }

  .accordion-header:hover {
    background: #ebebeb;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 0 15px;
    background: none;
    border: none;
  }

  .chemicals-right ul li {
    margin-left: 20px;
  }
  
  .accordion-content.open {
    max-height: 1250px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .chemicals_img {
    margin: 20px auto 0px;
    display: flex;
    justify-content: center;
  }

  .additional-changes {
    font-style: italic;
  }

  .accordion-header h6 {
    width: calc(100% - 25px);
  }

  @media screen and (max-width:991px) {

    .chemicals-outer {
flex-direction: column;
    }
    .chemicals-left {
width: 100%;
    }
    .chemicals-right {
        margin-top: 20px;
        width: 100%;
            }
            .accordion-content.open {
                max-height: 1650px;
            }
    
  }